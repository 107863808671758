import ApiService from "./ApiService";
import { IExpertiseArea } from "./interfaces";

class ExpertiseService extends ApiService {
  public async getExpertiseAreas(): Promise<IExpertiseArea[]> {
    const response = await this.request({
      url: "expertise",
    });
    return response.data;
  }
}

const instance = new ExpertiseService();

export default instance;
