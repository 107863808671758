import React from "react";
import { Col, Empty, Modal, Row, Space, Typography } from "antd";
import { ISpending } from "../../../utils/interfaces";

import { transformPrice } from "../../../helpers/transform";

import "./index.scss";

import { Link } from "react-router-dom";
import { DoubleRightOutlined } from "@ant-design/icons";

const { Text, Title } = Typography;

type PropTypes = {
  show: boolean;
  data: ISpending | undefined;
  handleClose: () => void;
};

const VideosessionDetails: React.FC<PropTypes> = ({
  show,
  data,
  handleClose,
}) => {
  if (!data) return <></>;
  return (
    <Modal
      title="Videosession Details:"
      visible={show}
      onCancel={handleClose}
      footer={null}
      className="videosessionDetails"
    >
      {data ? (
        <div>
          <Row>
            <Col span={12}>
              <Space direction="vertical" style={{ alignItems: "start" }}>
                <Title level={3}>
                  Videosession Info:{" "}
                  <Link
                    className="detailsLink"
                    to={`/admin/videosessions/${data.videosessionSnapshot.id}`}
                  >
                    <DoubleRightOutlined />
                  </Link>
                </Title>
                <Text strong>
                  Company: {data.videosessionSnapshot.companyName}{" "}
                  <Link
                    className="detailsLink"
                    to={`/admin/companies/${data.companyId}`}
                  >
                    <DoubleRightOutlined />
                  </Link>{" "}
                </Text>
                <Text strong>
                  Creator: {data.videosessionSnapshot.creatorName}
                </Text>
                <Text strong>
                  Start Time:{" "}
                  {new Date(data.startTime).toLocaleString().slice(0, -3)}{" "}
                </Text>
                <Text strong>
                  Description: {data.videosessionSnapshot.description}
                </Text>
                <Text strong>
                  Spent Minutes: {data.videosessionSnapshot.spentMinutes}m.
                </Text>
                <Text strong>
                  Specialization:{" "}
                  {data.videosessionSnapshot.expertiseArea.title_en}
                </Text>
                <Text strong>Cost Center: {data.costCenter || "-"}</Text>
                <Text>
                  Spending:{" "}
                  <Text strong>{transformPrice(data.spending)} CHF</Text>
                </Text>
                <Text>
                  Spending paid:{" "}
                  <Text strong>{transformPrice(data.spendingPaid)} CHF</Text>
                </Text>
                <Text>
                  Discount:{" "}
                  <Text strong>
                    {transformPrice(data.amountDiscounted)} CHF
                  </Text>
                </Text>
              </Space>
            </Col>
            <Col span={12}>
              <Space direction="vertical" style={{ alignItems: "start" }}>
                <Title level={3}>
                  Interpreter Info:{" "}
                  <Link
                    className="detailsLink"
                    to={`/admin/interpreters/${data.interpreterId}`}
                  >
                    <DoubleRightOutlined />
                  </Link>
                </Title>
                <Text>
                  Interpreter:{" "}
                  <Text strong>
                    {data.videosessionSnapshot.interpreterName}
                  </Text>
                </Text>
                <Text>
                  Earning:{" "}
                  <Text strong>{transformPrice(data.earning)} CHF</Text>
                </Text>
                <Text>
                  Earning Paid:{" "}
                  <Text strong>{transformPrice(data.earningPaid)} CHF</Text>
                </Text>
              </Space>
            </Col>
          </Row>
        </div>
      ) : (
        <Empty />
      )}
    </Modal>
  );
};

export default VideosessionDetails;
