import React, { useRef, useState } from "react";
import { Button, Modal } from "antd";
import { ErrorMessage, Formik, FormikProps } from "formik";
import { DatePicker, Form } from "formik-antd";
import Text from "antd/lib/typography/Text";
import * as Yup from "yup";
import moment, { Moment } from "moment";

const validationSchema = Yup.object().shape({
  startTime: Yup.string().trim().required("Valid before date is required."),
});

type PropTypes = {
  show: boolean;
  defaultDate: Date;
  handleClose: () => void;
  confirm: (
    startTime: string,
    setSubmitting: (isSubmitting: boolean) => void,
    clearState: () => void
  ) => void;
};

const ChangeStartTime: React.FC<PropTypes> = ({
  show,
  defaultDate,
  handleClose,
  confirm,
}) => {
  const formikRef = useRef<FormikProps<{ startTime: string }>>(null);
  const [selectedDate, setSelectedDate] = useState<Moment>(moment(defaultDate));
  const disabledDate = (current: Moment) =>
    current && current < moment().subtract(1, "day").endOf("day");

  const disabledHours = () => {
    if (selectedDate?.isSame(new Date(), "day")) {
      const disabledHours = [];
      for (let i = 0; i < moment().hour(); i++) {
        disabledHours.push(i);
      }

      return disabledHours;
    }
    return [];
  };
  const disabledMinutes = () => {
    if (
      selectedDate?.isSame(new Date(), "day") &&
      selectedDate?.hour() <= moment().hour()
    ) {
      const disabledMinutes = [];
      for (let i = 0; i < moment().minute(); i++) {
        disabledMinutes.push(i);
      }

      return disabledMinutes;
    }
    return [];
  };

  const onOk = (time: any) => {
    if (time < moment()) {
      setSelectedDate(
        time?.set({
          hour: moment().hour(),
          minute: Math.ceil(moment().minute() / 5) * 5,
        })
      );
    } else {
      setSelectedDate(time);
    }
  };
  const onSelect = (time: any) => {
    setSelectedDate(time);
  };

  return (
    <Modal
      title="Change videosession start time:"
      visible={show}
      onCancel={() => {
        handleClose();
        formikRef.current?.resetForm();
      }}
      footer={null}
    >
      <div className="createVoucher">
        <Formik
          innerRef={formikRef}
          initialValues={{
            startTime: selectedDate?.toISOString(),
          }}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={(values, { setSubmitting, resetForm }) => {
            confirm(values.startTime, setSubmitting, resetForm);
          }}
        >
          {({ isSubmitting, submitForm }) => (
            <Form>
              <div className="dateGroup">
                <Text strong>Valid before:</Text>
                <DatePicker
                  name="startTime"
                  format="DD/MM/YYYY HH:mm"
                  placeholder="dd/mm/yyyy hh:mm"
                  disabledDate={disabledDate}
                  onSelect={onSelect}
                  disabledHours={disabledHours}
                  disabledMinutes={disabledMinutes}
                  minuteStep={5}
                  showNow={false}
                  onOk={onOk}
                  showTime={{
                    // hideDisabledOptions: true,
                    defaultValue: moment("00:00:00", "HH:mm:ss"),
                  }}
                />
                <ErrorMessage
                  name="startTime"
                  component="p"
                  className="error"
                />
              </div>
              <Button
                className="saveBtn"
                onClick={submitForm}
                disabled={isSubmitting}
                loading={isSubmitting}
                type="primary"
              >
                Update
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default ChangeStartTime;
