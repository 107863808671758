import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "antd";
import { ErrorMessage, Formik, FormikProps } from "formik";
import Text from "antd/lib/typography/Text";
import * as Yup from "yup";
import { Form, Input } from "formik-antd";

const validationSchema = Yup.object().shape({
  description: Yup.string().trim(),
});

type PropTypes = {
  show: boolean;
  name: string;
  value?: string;
  handleClose: () => void;
  confirm: (
    name: string,
    value: string,
    setSubmitting: (isSubmitting: boolean) => void,
    clearState: () => void
  ) => void;
  textarea?: boolean
};

const UpdateVideosessionValue: React.FC<PropTypes> = ({
  show,
  name,
  handleClose,
  confirm,
  textarea = false,
  value = ''
}) => {
  const formikRef = useRef<FormikProps<{ value: string }>>(null);
  const [valueField, setValueField] = useState<string>(value);

  useEffect(() => {
    setValueField(show ? value : "");
  }, [show, value]);

  return (
    <Modal
      title={`Update videosession ${name}:`}
      visible={show}
      onCancel={() => {
        handleClose();
        formikRef.current?.resetForm();
      }}
      footer={null}
    >
      <div className="createVoucher">
        <Formik
          innerRef={formikRef}
          initialValues={{
            value: valueField,
          }}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={(values, { setSubmitting, resetForm }) => {
            confirm(name, values.value, setSubmitting, resetForm);
          }}
        >
          {({ isSubmitting, submitForm }) => (
            <Form>
              <div className="dateGroup">
                <Text strong>Type new value:</Text>
                {textarea ? <Input.TextArea name="value" /> : <Input name="value" />}

                <ErrorMessage name="value" component="p" className="error" />
              </div>
              <Button
                className="saveBtn"
                onClick={submitForm}
                disabled={isSubmitting}
                loading={isSubmitting}
                type="primary"
              >
                Update
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default UpdateVideosessionValue;
