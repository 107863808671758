import ApiService from "./ApiService";
import { ICompany, IFilterItems, IVoucher } from "./interfaces";

class VoucherService extends ApiService {
  public async getVouchers({
    current,
    pageSize,
    status,
  }: IFilterItems): Promise<{ vouchers: IVoucher[]; count: number }> {
    const offset = ((current as number) - 1) * (pageSize as number);
    let url = `voucher/admin?offset=${offset}`;
    if (status) url += `&status=${status}`;

    const response = await this.request({
      url,
    });

    return response.data;
  }

  public async createVoucher(data: IVoucher): Promise<IVoucher> {
    const response = await this.request({
      url: "voucher",
      method: "POST",
      data,
    });

    return response.data;
  }

  public async getVoucherDetails(id: number): Promise<IVoucher> {
    const response = await this.request({
      url: `voucher/admin/${id}`,
    });

    return response.data;
  }

  public async activateVoucher(
    code: string,
    companyId: string
  ): Promise<ICompany> {
    const response = await this.request({
      url: `voucher/activate/admin/${code}/${companyId}`,
    });

    return response.data;
  }

  public async updateVoucherStatus(
    id: number,
    date: string
  ): Promise<IVoucher> {
    const response = await this.request({
      url: `voucher/${id}/status`,
      method: "PATCH",
      data: {
        date,
      },
    });

    return response.data;
  }
}

const instance = new VoucherService();

export default instance;
