import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  RadioChangeEvent,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import { ErrorMessage, Formik } from "formik";
import { DatePicker, Form, Radio, Select, TimePicker } from "formik-antd";
import Text from "antd/lib/typography/Text";
import moment, { Moment } from "moment";
import * as Yup from "yup";

import InterpreterService from "../../utils/InterpreterService";
import LanguagesService from "../../utils/LanguagesService";
import ExpertiseService from "../../utils/ExpertiseService";
import { sortLanguages } from "../../utils/sortLanguages";

import "./index.scss";
import {
  IExpertiseArea,
  IInterpreterUser,
  ILanguage,
} from "../../utils/interfaces";
import dialectCountries from "../../utils/dialect-countries";
import { ColumnsType } from "antd/lib/table";
import { Link } from "react-router-dom";

enum TimeRange {
  M30 = "m30",
  H1 = "h1",
  H2 = "h2",
  MORE = "more",
}

interface IInterpreterItem {
  key: number;
  firstName: string;
  lastName: string;
  email: string;
}

const { Title } = Typography;

const validationSchema = Yup.object().shape({
  startDate: Yup.date().required("Date is required"),
  startTime: Yup.date()
    .required("Time is required")
    .test("start-time", "Time should be later", function (value, ref) {
      if (
        value &&
        new Date(ref.parent.startDate).toDateString() ===
          new Date().toDateString()
      ) {
        const minStart = new Date(new Date().getTime() + 60 * 1000);
        const currentStart = new Date(value);
        if (currentStart < minStart) {
          return false;
        }
      }
      return true;
    }),
  timeRange: Yup.string().required("Time range is required"),
  expertiseArea: Yup.string().required("Expertise Area is required"),
  translateFrom: Yup.string().trim().required("Target language is required"),
  translateTo: Yup.string()
    .trim()
    .when("type", {
      is: (val: string) => val === "spoken",
      then: Yup.string().required("Source language is required"),
    })
    .when("translateFrom", {
      is: (val: string) => !!(val && val.length > 0),
      then: Yup.string().notOneOf(
        [Yup.ref("translateFrom")],
        "Languages shouldn't match"
      ),
    }),
  dialect: Yup.string().trim(),
  translateToSign: Yup.string()
    .trim()
    .when("type", {
      is: (val: string) => val === "sign",
      then: Yup.string().required("Source language is required"),
    }),
});

const FindInterpreter: React.FC = () => {
  const [selectedDate, setSelectedDate] = useState<Moment | null>(null);
  const [expertiseAreas, setExpertiseAreas] = useState<IExpertiseArea[]>([]);
  const [spokenLanguages, setSpokenLanguages] = useState<ILanguage[]>([]);
  const [signLanguages, setSignLanguages] = useState<ILanguage[]>([]);
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState<IInterpreterItem[]>([]);
  const [interpretersData, setInterpretersData] = useState<IInterpreterUser[]>(
    []
  );

  const disabledDate = (current: Moment) =>
    current && current.endOf("day") < moment().endOf("day");

  const disabledMinutes = (hour: number): number[] => {
    const disabledMinutes = [-1];

    if (
      selectedDate?.format("DD/MM/YYYY") === moment().format("DD/MM/YYYY") &&
      moment().hour() === hour
    ) {
      for (let i = 0; i < moment().minute(); i++) {
        disabledMinutes.push(i);
      }
    }

    return disabledMinutes;
  };

  const disabledHours = (): number[] => {
    if (selectedDate?.format("DD/MM/YYYY") === moment().format("DD/MM/YYYY")) {
      const disabledHours = [-1];
      for (let i = 0; i < moment().hour(); i++) {
        disabledHours.push(i);
      }
      return disabledHours;
    }
    return [];
  };

  const getLanguageOptions = (type: string | undefined) => {
    if (type === "spoken")
      return spokenLanguages.sort(sortLanguages).map((lang) => (
        <Select.Option key={lang.code} value={lang.code}>
          {lang.title_en}
        </Select.Option>
      ));
    return signLanguages.sort(sortLanguages).map((lang) => (
      <Select.Option key={lang.code} value={lang.code}>
        {lang.title_en}
      </Select.Option>
    ));
  };

  useEffect(() => {
    const getLanguages = async () => {
      const spoken = await LanguagesService.getSpokenLanguages();
      const sign = await LanguagesService.getSignLanguages();
      const areas = await ExpertiseService.getExpertiseAreas();

      setSpokenLanguages(spoken);
      setSignLanguages(sign);
      setExpertiseAreas(areas);
    };

    getLanguages();
  }, []);

  useEffect(() => {
    if (!interpretersData) return;

    const dataToSet: IInterpreterItem[] = interpretersData.map(
      (interpreter) => {
        const interpreterItem: IInterpreterItem = {
          key: interpreter.id,
          firstName: interpreter.firstName,
          lastName: interpreter.lastName,
          email: interpreter.email,
        };
        return interpreterItem;
      }
    );

    setDataSource(dataToSet);
  }, [interpretersData, signLanguages, spokenLanguages]);

  const getInterpreters = async (values: any) => {
    setLoading(true);
    const interpreters = await InterpreterService.getAvailableInterpreters(
      values
    );
    setInterpretersData(interpreters);
    setLoading(false);
  };

  const columns: ColumnsType<IInterpreterItem> = [
    {
      title: "Id",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Name",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Surname",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Details",
      dataIndex: "key",
      key: "key",
      render: (id: string) => (
        <Link to={`/admin/interpreters/${id}`}>Details</Link>
      ),
    },
  ];

  return (
    <div className="findInterpreterPage">
      <Title level={2}>Find Free Interpreter</Title>
      <Formik
        initialValues={{
          startDate: "",
          startTime: "",
          type: "spoken",
          timeRange: TimeRange.M30,
          gender: "",
          expertiseArea: 1,
          translateFrom: "",
          translateTo: "",
          translateToSign: "",
          dialect: "",
        }}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          getInterpreters(values);

          setSubmitting(false);
        }}
      >
        {({ values, isSubmitting }) => (
          <Form>
            <Row>
              <Col span={12}>
                <Row>
                  <div className="dateGroup">
                    <Text strong>Choose Date:</Text>
                    <DatePicker
                      name="startDate"
                      format="DD/MM/YYYY"
                      placeholder="dd/mm/yyyy"
                      disabledDate={disabledDate}
                      onSelect={setSelectedDate}
                    />
                    <ErrorMessage
                      name="startDate"
                      component="p"
                      className="error"
                    />
                  </div>
                  <div className="dateGroup">
                    <Text strong>Choose time:</Text>
                    <TimePicker
                      name="startTime"
                      format="HH:mm"
                      showNow={false}
                      minuteStep={15}
                      disabledMinutes={disabledMinutes}
                      disabledHours={disabledHours}
                    />
                    <ErrorMessage
                      name="startTime"
                      component="p"
                      className="error"
                    />
                  </div>
                  <div className="dateGroup">
                    <Text strong>Time range:</Text>
                    <Select
                      name="timeRange"
                      defaultValue={TimeRange.M30}
                      style={{ width: 120 }}
                      options={[
                        {
                          value: TimeRange.M30,
                          label: "Up to 30 min",
                        },
                        {
                          value: TimeRange.H1,
                          label: "30 - 60 min",
                        },
                        {
                          value: TimeRange.H2,
                          label: "1h - 2h",
                        },
                        {
                          value: TimeRange.MORE,
                          label: "2h+",
                        },
                      ]}
                    />
                    <ErrorMessage
                      name="timeRange"
                      component="p"
                      className="error"
                    />
                  </div>
                </Row>
                <Row>
                  <div className="genderGroup">
                    <Text strong>Select gender:</Text>
                    <Radio.Group name="gender" defaultValue="">
                      <Radio name="gender" value="male">
                        Male
                      </Radio>
                      <Radio name="gender" value="female">
                        Female
                      </Radio>
                      <Radio name="gender" value="">
                        Doesn't metter
                      </Radio>
                    </Radio.Group>
                  </div>
                </Row>
                <Row>
                  <div className="selectGroup">
                    <Text strong>Areas of expertise:</Text>
                    <Select
                      style={{ width: 200 }}
                      name="expertiseArea"
                      placeholder="Select area"
                    >
                      {expertiseAreas.map((ea) => (
                        <Select.Option key={ea.id} value={ea.id}>
                          {ea.title_en}
                        </Select.Option>
                      ))}
                    </Select>
                    <ErrorMessage
                      name="expertiseArea"
                      component="p"
                      className="error"
                    />
                  </div>
                </Row>
              </Col>
              <Col span={12}>
                <Row>
                  <div className="languageGroup">
                    <Text strong>Language specification:</Text>
                    <Radio.Group name="type">
                      <Radio name="type" value="spoken">
                        I need interpretation into the spoken spoken
                      </Radio>
                      <Radio name="type" value="sign">
                        I need interpretation into the sign language
                      </Radio>
                    </Radio.Group>
                  </div>
                </Row>
                <Row>
                  <div className="languageGroup">
                    <Text strong>Translate from:</Text>
                    <Select
                      showSearch
                      style={{ width: 200 }}
                      name="translateFrom"
                      placeholder="Select language"
                    >
                      {getLanguageOptions("spoken")}
                    </Select>
                    <ErrorMessage
                      name="translateFrom"
                      component="p"
                      className="error"
                    />
                  </div>
                  <div className="languageGroup">
                    <Text strong>Translate to:</Text>
                    <Select
                      showSearch
                      style={{ width: 200 }}
                      name={
                        values.type === "spoken"
                          ? "translateTo"
                          : "translateToSign"
                      }
                      placeholder="Select language"
                    >
                      {getLanguageOptions(values.type)}
                    </Select>
                    <ErrorMessage
                      name={
                        values.type === "spoken"
                          ? "translateTo"
                          : "translateToSign"
                      }
                      component="p"
                      className="error"
                    />
                  </div>
                  {values.type === "spoken" && (
                    <div className="dialectGroup">
                      <Space
                        direction="vertical"
                        style={{ alignItems: "start" }}
                      >
                        <Text strong>Dialect:</Text>
                        <Select
                          name="dialect"
                          showSearch
                          style={{ width: 200 }}
                          filterOption={(input, option) =>
                            !!(option?.label as string)
                              ?.toLowerCase()
                              .startsWith(input.toLowerCase())
                          }
                        >
                          {dialectCountries.sort(sortLanguages).map((lang) => (
                            <Select.Option
                              key={lang.code}
                              label={lang.title_en}
                              value={lang.code}
                            >
                              {lang.title_en}
                            </Select.Option>
                          ))}
                        </Select>
                        <ErrorMessage
                          name="dialect"
                          component="p"
                          className="error"
                        />
                      </Space>
                    </div>
                  )}
                </Row>
              </Col>
            </Row>
            <Row className="submit-btn">
              <Button disabled={isSubmitting} type="primary" htmlType="submit">
                Submit
              </Button>
            </Row>
          </Form>
        )}
      </Formik>

      <Table dataSource={dataSource} columns={columns} loading={loading} />
    </div>
  );
};

export default FindInterpreter;
