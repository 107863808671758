import React, { Key, useCallback, useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Input,
  Row,
  Slider,
  Space,
  Table,
  TablePaginationConfig,
} from "antd";
import Highlighter from "react-highlight-words";
import InterpreterService from "../../utils/InterpreterService";
import LanguagesService from "../../utils/LanguagesService";
import {
  IFilterItems,
  ILanguage,
  IInterpreterUser,
} from "../../utils/interfaces";
import { Link } from "react-router-dom";
import { useQuery } from "../../hooks/useQuery";
import { ColumnsType, FilterDropdownProps } from "antd/lib/table/interface";
import { FilterFilled, SearchOutlined } from "@ant-design/icons";
import { sortLanguages } from "../../utils/sortLanguages";

interface IInterpreterItem {
  key: number;
  firstName: string;
  lastName: string;
  status: string;
  nativeLanguages: string[];
  workingLanguages: string[];
  onboarded: string;
  rating: string;
  email: string;
}

const Interperters: React.FC = () => {
  const [dataSource, setDataSource] = useState<IInterpreterItem[]>([]);
  const [interpretersData, setinterpretersData] = useState<IInterpreterUser[]>(
    []
  );
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [spokenLanguages, setSpokenLanguages] = useState<ILanguage[]>([]);
  const [signLanguages, setSignLanguages] = useState<ILanguage[]>([]);
  const [filter, setFilter] = useState<IFilterItems>({
    current: 0,
    pageSize: 0,
    status: null,
    firstName: "",
    lastName: "",
    email: "",
    nativeLanguages: [],
    workingLanguages: [],
    ratingRange: [0, 5],
  });
  const [state, setState] = useState({
    searchText: "",
    searchedColumn: "",
    ratingRange: [0, 5],
  });
  const firstNameSearchInput = useRef<Input>(null);
  const lastNameSearchInput = useRef<Input>(null);
  const emailSearchInput = useRef<Input>(null);

  const status = useQuery("status");

  const findLanguage = useCallback(
    (code: string): string => {
      switch (code.length) {
        case 2:
          return (
            spokenLanguages.find((lang) => lang.code === code)?.title_en || ""
          );
        case 3:
          return (
            signLanguages.find((lang) => lang.code === code)?.title_en || ""
          );
        default:
          return "";
      }
    },
    [signLanguages, spokenLanguages]
  );

  useEffect(() => {
    const getLanguages = async () => {
      const spoken = await LanguagesService.getSpokenLanguages();
      const sign = await LanguagesService.getSignLanguages();

      setSpokenLanguages(spoken.sort(sortLanguages));
      setSignLanguages(sign.sort(sortLanguages));
    };

    getLanguages();
  }, []);

  useEffect(() => {
    if (!spokenLanguages || !signLanguages || !interpretersData) return;

    const dataToSet: IInterpreterItem[] = interpretersData.map(
      (interpreter) => {
        const interpreterItem: IInterpreterItem = {
          key: interpreter.profileId,
          firstName: interpreter.firstName,
          lastName: interpreter.lastName,
          status: interpreter.isActive ? "active" : "not active",
          nativeLanguages: interpreter.nativeLanguages.map(
            (lang, i, arr) =>
              `${lang.title_en}${i === arr.length - 1 ? "" : ", "}`
          ),
          workingLanguages: interpreter.workingLanguages.map(
            (lang, i, arr) =>
              `${findLanguage(lang.code)}${i === arr.length - 1 ? "" : ", "}`
          ),
          email: interpreter.email,
          onboarded:
            interpreter.address &&
            interpreter.languages?.length &&
            interpreter.expertiseAreas?.length &&
            interpreter.about
              ? "true"
              : "false",
          rating: interpreter.rating?.overall?.average
            ? interpreter.rating?.overall?.average.toFixed(2)
            : "-",
        };
        return interpreterItem;
      }
    );

    setDataSource(dataToSet);
  }, [interpretersData, signLanguages, spokenLanguages, findLanguage]);

  useEffect(() => {
    const getInterpreters = async () => {
      if (!filter.current || !filter.pageSize) return;
      setLoading(true);

      const { interpreters, count } = await InterpreterService.getInterpreters(
        filter
      );

      setinterpretersData(interpreters);
      setTotalCount(count);

      setLoading(false);
    };
    getInterpreters();
  }, [filter]);

  useEffect(() => {
    setFilter({
      current: 1,
      pageSize: 10,
      status,
      firstName: "",
      lastName: "",
      email: "",
      ratingRange: [0, 5],
    });
  }, [status]);

  const handleTableChange = ({
    pagination,
    filters,
  }: {
    pagination: TablePaginationConfig;
    filters: any;
  }) => {
    const { current, pageSize } = pagination;

    let onboardedToSet = null;
    if (!!filters?.onboarded?.length) {
      onboardedToSet = filters.onboarded[0];
    }
    let nativeLanguagesToSet = null;
    let workingLanguagesToSet = null;
    if (!!filters?.nativeLanguages?.length) {
      nativeLanguagesToSet =
        filters.nativeLanguages.join("-") || filter.nativeLanguages;
    }
    if (!!filters?.workingLanguages?.length) {
      workingLanguagesToSet =
        filters.workingLanguages.join("-") || filter.workingLanguages;
    }
    setFilter({
      ...filter,
      current: current as number,
      pageSize: pageSize as number,
      onboarded: onboardedToSet,
      nativeLanguages: nativeLanguagesToSet,
      workingLanguages: workingLanguagesToSet,
      ratingRange: state.ratingRange,
      [state.searchedColumn]: state.searchText,
    });
  };

  const handleSearch = (
    selectedKeys: Key[],
    confirm: () => void,
    dataIndex: string
  ) => {
    confirm();
    setState({
      ...state,
      searchText: selectedKeys[0] as string,
      searchedColumn: dataIndex,
    });
  };

  const getRefToSet = (dataIndex: string) => {
    switch (dataIndex) {
      case "firstName":
        return firstNameSearchInput;
      case "lastName":
        return lastNameSearchInput;
      case "email":
        return emailSearchInput;
    }
  };

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: FilterDropdownProps) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={getRefToSet(dataIndex)}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              setState({
                ...state,
                searchText: e.target.value,
                searchedColumn: dataIndex,
              });
            }}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              onClick={() => {
                setSelectedKeys([]);
                if (clearFilters) clearFilters();
                confirm();
                setState({
                  ...state,
                  searchText: "",
                  searchedColumn: dataIndex,
                });
                setFilter({ ...filter, [dataIndex]: "" });
                setTimeout(() => getRefToSet(dataIndex)?.current?.focus(), 100);
              }}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
          </Space>
        </div>
      );
    },
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => getRefToSet(dataIndex)?.current?.focus(), 100);
      }
    },
    render: (text: string) =>
      state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const getSliderFilterProps = () => ({
    filterDropdown: ({
      setSelectedKeys,
      confirm,
      selectedKeys,
    }: FilterDropdownProps) => {
      return (
        <div
          className="custom-filter-dropdown ant-table-filter-dropdown"
          style={{ minWidth: "20rem", padding: "0.5rem 1rem" }}
        >
          <Row>
            <Col span={4}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div>
                  <strong>Min:</strong>
                </div>
                <div>0</div>
              </div>
            </Col>
            <Col span={16}>
              <Slider
                range
                value={
                  !!selectedKeys.length
                    ? (selectedKeys as [number, number])
                    : [0, 5]
                }
                min={0}
                max={5}
                step={0.1}
                onChange={(range) => {
                  setSelectedKeys(range ? range : []);
                  setState({ ...state, ratingRange: range });
                }}
              />
            </Col>
            <Col span={4}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "15px",
                }}
              >
                <div>
                  <strong>Max:</strong>
                </div>
                <div>5</div>
              </div>
            </Col>
          </Row>
          <Row>
            <Space
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "15px",
                width: "100%",
              }}
            >
              <Button
                onClick={() => {
                  setSelectedKeys([0, 5]);
                  setState({ ...state, ratingRange: [0, 5] });
                }}
                size="small"
                style={{ width: 90 }}
              >
                Reset
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  setState({ ...state, ratingRange: selectedKeys as number[] });
                  confirm();
                }}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
            </Space>
          </Row>
        </div>
      );
    },
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (
        !visible &&
        (filter.ratingRange?.[0] !== state.ratingRange[0] ||
          filter.ratingRange?.[1] !== state.ratingRange[1])
      )
        setFilter({ ...filter, ratingRange: state.ratingRange });
    },
    filterIcon: () => (
      <FilterFilled
        style={{
          color:
            filter.ratingRange?.[0] !== 0 || filter.ratingRange?.[1] !== 5
              ? "#1890ff"
              : undefined,
        }}
      />
    ),
  });

  const columns: ColumnsType<IInterpreterItem> = [
    {
      title: "Id",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Name",
      dataIndex: "firstName",
      key: "firstName",
      ...getColumnSearchProps("firstName"),
    },
    {
      title: "Surname",
      dataIndex: "lastName",
      key: "lastName",
      ...getColumnSearchProps("lastName"),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ...getColumnSearchProps("email"),
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Native languages",
      dataIndex: "nativeLanguages",
      key: "nativeLanguages",
      filters: [...spokenLanguages, ...signLanguages].map((lang) => ({
        text: lang.title_en,
        value: lang.code,
      })),
      filterSearch: true,
      filtered: !!filter.nativeLanguages?.length,
    },
    {
      title: "Working languages",
      dataIndex: "workingLanguages",
      key: "workingLanguages",
      filters: [...spokenLanguages, ...signLanguages].map((lang) => ({
        text: lang.title_en,
        value: lang.code,
      })),
      filterSearch: true,
      filtered: !!filter.workingLanguages?.length,
    },
    {
      title: "Application completed",
      dataIndex: "onboarded",
      key: "onboarded",
      filters: ["true", "false"].map((onboarded) => ({
        text: onboarded,
        value: onboarded,
      })),
      filtered: !!filter.onboarded,
      filterMultiple: false,
    },
    {
      title: "Overal Rating",
      dataIndex: "rating",
      key: "rating",
      ...getSliderFilterProps(),
    },
    {
      title: "Details",
      dataIndex: "key",
      key: "key",
      render: (id: string) => (
        <Link to={`/admin/interpreters/${id}`}>Details</Link>
      ),
    },
  ];

  return (
    <div className="App">
      <p>Interperters</p>
      <Table
        dataSource={dataSource}
        columns={columns}
        scroll={{ x: 1000 }}
        pagination={{
          ...filter,
          total: totalCount,
          pageSizeOptions: ["10", "25", "50"],
          showSizeChanger: true,
        }}
        loading={loading}
        onChange={(pagination, filters) =>
          handleTableChange({ pagination, filters })
        }
      />
    </div>
  );
};

export default Interperters;
