import React, { useEffect, useState } from "react";
import { Button, Col, InputNumber, Modal, Row, Slider } from "antd";

import "./index.scss";

type PropTypes = {
  show: boolean;
  additionalPrice: number;
  handleClose: () => void;
  confirm: (minimalPrice: number) => void;
};

const MinimalPriceModal: React.FC<PropTypes> = ({
  show,
  additionalPrice,
  handleClose,
  confirm,
}) => {
  const [minimalPrice, setMinimalPrice] = useState(35);

  useEffect(() => setMinimalPrice(35 + additionalPrice), [additionalPrice]);

  const handleConfirm = () => {
    confirm(minimalPrice);
  };

  const formatter = (value: number | undefined) => {
    return `${35}CHF + ${(value as number) - 35}CHF`;
  };

  return (
    <Modal
      title="Update minimal price:"
      visible={show}
      onCancel={() => {
        setMinimalPrice(35 + additionalPrice);
        handleClose();
      }}
      footer={null}
    >
      <div className="wrapper">
        <Row style={{ width: 300 }}>
          <Col span={18}>
            <Slider
              min={35}
              max={100}
              tipFormatter={formatter}
              value={minimalPrice}
              onChange={setMinimalPrice}
            />
          </Col>
          <Col span={6}>
            <InputNumber
              min={35}
              max={100}
              style={{ maxWidth: 60, marginLeft: 15 }}
              value={minimalPrice}
              onChange={setMinimalPrice}
            />
          </Col>
        </Row>

        <Button
          disabled={minimalPrice === 35 + additionalPrice}
          onClick={handleConfirm}
          type="primary"
        >
          Update
        </Button>
      </div>
    </Modal>
  );
};

export default MinimalPriceModal;
