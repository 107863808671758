import ApiService from "./ApiService";
import { IInterpreterUser, ISpending } from "./interfaces";

class FinanceService extends ApiService {
  public async getCompanySpendings(
    id: string,
    date: string
  ): Promise<ISpending[]> {
    const [month, year] = date.split("-");

    const response = await this.request({
      url: `finance/company/${id}/spendings?month=${month}&year=${year}`,
    });

    return response.data.spendings;
  }

  public async markSpendingAsPaid(
    id: number,
    date: string
  ): Promise<ISpending> {
    const response = await this.request({
      url: `finance/spendings/${id}/mark-spending`,
      method: "POST",
      data: {
        date,
      },
    });

    return response.data;
  }

  public async discountSpending(
    id: number,
    amount: number
  ): Promise<ISpending> {
    const response = await this.request({
      url: `finance/spendings/${id}/discount-spending`,
      method: "POST",
      data: {
        amount,
      },
    });

    return response.data;
  }

  public async removeDiscount(id: number): Promise<ISpending> {
    const response = await this.request({
      url: `finance/spendings/${id}/cancel-discount`,
      method: "GET",
    });

    return response.data;
  }

  public async markSpendingsAsPaid(
    id: number,
    period: string,
    date: string
  ): Promise<ISpending[]> {
    const [month, year] = period.split("-");

    const response = await this.request({
      url: `finance/company/${id}/mark-spendings?month=${month}&year=${year}`,
      method: "POST",
      data: {
        date,
      },
    });

    return response.data.spendings;
  }

  public async isInvoiceExist(id: string, period: string) {
    const [month, year] = period.split("-");

    const response = await this.request({
      url: `finance/company/${id}/month-stats?month=${month}&year=${year}`,
    });

    return response.data;
  }

  public async generateMonthlyInvoice(
    id: string,
    period: string
  ): Promise<{ name: string }> {
    const [month, year] = period.split("-");

    const response = await this.request({
      url: `finance/company/${id}/monthly-invoice?month=${month}&year=${year}`,
    });

    return response.data;
  }

  public async generateMonthlyInvoiceByCostCenter(
    id: string,
    period: string,
    costCenter: string
  ): Promise<{ name: string }> {
    const [month, year] = period.split("-");

    const response = await this.request({
      url: `finance/company/${id}/monthly-invoice/${costCenter}?month=${month}&year=${year}`,
    });

    return response.data;
  }

  public async checkInvoiceStatus(name: string): Promise<{ status: string }> {
    const response = await this.request({
      url: `finance/monthly-invoice/check/${name}`,
    });

    return response.data;
  }

  public async getInterpreterYearEarnings(
    id: string,
    year: string
  ): Promise<string> {
    const response = await this.request({
      url: `finance/earnings/year-stats/${id}/${year}`,
    });

    return response?.data?.total?.sum;
  }

  public async downloadInvoicesRange(data: {
    from: string;
    to: string;
    fromDate?: string;
    toDate?: string;
    interpreter?: IInterpreterUser;
  }) {
    const { from, to, interpreter, fromDate, toDate } = data;
    const response = await this.request({
      url: `finance/credit-notes/${interpreter?.profileId || 0}`,
      headers: {
        "Content-Type": "application/zip",
      },
      responseType: "blob",
      method: "POST",
      data: {
        from,
        to,
      },
    });
    const file = new File(
      [response.data],
      `${
        interpreter
          ? `${interpreter?.firstName}_${interpreter?.lastName}`
          : `All_Interpreters_${fromDate}_${toDate}`
      }_invoices.zip`
    );
    const url = window.URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `${
        interpreter
          ? `${interpreter?.firstName}_${interpreter?.lastName}`
          : `All_Interpreters_${fromDate}_${toDate}`
      }_invoices.zip`
    );
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode?.removeChild(link);
  }
}

const instance = new FinanceService();

export default instance;
