import React, { FC } from "react";
import { Input, Select } from "formik-antd";
import { Typography } from "antd";

const { Option } = Select;
type PropsTypes = {
  placeholder: { en: string; de: string; fr: string; it: string };
  title: { en: string; de: string; fr: string; it: string };
  language: "de" | "en" | "fr" | "it";
  options?: { en: string; de: string; fr: string; it: string }[];
  index: number;
  required?: boolean;
  value?: string;
};
const AdditionalField: FC<PropsTypes> = ({
  placeholder,
  title,
  language,
  options,
  index,
  value,
  required = false,
}) => {
  if (options) {
    return (
      <>
        <Typography.Text>
          {title[language]} {required && "*"}
        </Typography.Text>
        <Select
          name={`additionalFields[${index}].value`}
          className="selectBorder"
          placeholder={placeholder[language]}
          defaultValue={value}
        >
          {options.map((option) => (
            <Option value={option[language]} key={option[language]}>
              {option[language]}
            </Option>
          ))}
        </Select>
      </>
    );
  }
  return (
    <>
      <Typography.Text>
        {title[language]} {required && "*"}
      </Typography.Text>
      <Input
        placeholder={placeholder[language]}
        name={`additionalFields[${index}].value`}
        defaultValue={value}
      />
    </>
  );
};
export default AdditionalField;
