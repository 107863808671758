import { ITokens } from "./interfaces";

class TokenStorage {
  private readonly storageKeyAccess = "@access_token";

  private readonly storageKeyRefresh = "@refresh_token";

  private readonly storageKeyNotification = "@notification_token";

  private accessToken: string | null =
    typeof window !== "undefined"
      ? localStorage.getItem(this.storageKeyAccess)
      : null;

  private refreshToken: string | null =
    typeof window !== "undefined"
      ? localStorage.getItem(this.storageKeyRefresh)
      : null;

  private notificationSecret: string | null =
    typeof window !== "undefined"
      ? localStorage.getItem(this.storageKeyNotification)
      : null;

  public set(
    accessToken: string,
    refreshToken: string,
    notificationSecret: string
  ): void {
    localStorage.setItem(this.storageKeyAccess, accessToken);
    localStorage.setItem(this.storageKeyRefresh, refreshToken);
    localStorage.setItem(this.storageKeyNotification, notificationSecret);

    this.accessToken = accessToken;
    this.refreshToken = refreshToken;
    this.notificationSecret = notificationSecret;
  }

  public getAccessToken(): string | null {
    if (this.accessToken) {
      return this.accessToken;
    }

    const accessToken = localStorage.getItem(this.storageKeyAccess);
    const refreshToken = localStorage.getItem(this.storageKeyRefresh);
    const notificationSecret = localStorage.getItem(this.storageKeyAccess);

    this.accessToken = accessToken;
    this.refreshToken = refreshToken;
    this.notificationSecret = notificationSecret;

    return accessToken;
  }

  public get(): ITokens | null {
    if (this.accessToken && this.refreshToken && this.notificationSecret) {
      return {
        accessToken: this.accessToken,
        refreshToken: this.refreshToken,
        notificationSecret: this.notificationSecret,
      };
    }

    const accessToken = localStorage.getItem(this.storageKeyAccess);
    const refreshToken = localStorage.getItem(this.storageKeyRefresh);
    const notificationSecret = localStorage.getItem(this.storageKeyAccess);

    this.accessToken = accessToken;
    this.refreshToken = refreshToken;
    this.notificationSecret = notificationSecret;

    return accessToken && refreshToken && notificationSecret
      ? { accessToken, refreshToken, notificationSecret }
      : null;
  }

  public remove(): void {
    localStorage.removeItem(this.storageKeyAccess);
    localStorage.removeItem(this.storageKeyRefresh);
    localStorage.removeItem(this.storageKeyNotification);
    this.accessToken = null;
    this.refreshToken = null;
    this.notificationSecret = null;
  }
}

const tokenStorage = new TokenStorage();

export default tokenStorage;
