import React, { useState } from "react";
import { Button, Modal, Select, Upload } from "antd";
import { CertificateType } from "../../utils/interfaces";
import { UploadOutlined } from "@ant-design/icons";

import "./index.scss";

const { Option } = Select;

type PropTypes = {
  status: {
    show: boolean;
    type: string;
  };
  handleClose: () => void;
  confirm: (file: File, type: string) => void;
};

const FileUploadModal: React.FC<PropTypes> = ({
  status,
  handleClose,
  confirm,
}) => {
  const [type, setType] = useState<CertificateType>("cv");
  const [uploading, setUploading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<File>();

  const uploadFile = async (options: any) => {
    setUploading(true);

    const { onSuccess, file } = options;
    try {
      setUploadedFile(file);
      onSuccess();
    } catch (err) {
      console.log("Eroor: ", err);
    } finally {
      setUploading(false);
    }
  };

  const handleUplaod = async () => {
    if (!uploadedFile) return;
    confirm(uploadedFile, type);
  };

  return (
    <Modal
      title="Upload file"
      visible={status.show}
      onCancel={handleClose}
      footer={null}
    >
      <div className="fileUpload">
        {status.type === "certificate" && (
          <Select value={type} style={{ width: 120 }} onChange={setType}>
            <Option value="cv">CV</Option>
            <Option value="diploma">Diploma</Option>
            <Option value="reference">Reference</Option>
          </Select>
        )}
        <Upload
          name="upload"
          accept=".pdf,.jpg,.jpeg,.png,.doc,.docx"
          customRequest={uploadFile}
          disabled={uploading}
          maxCount={1}
        >
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload>

        <Button
          disabled={!uploadedFile || uploading}
          onClick={handleUplaod}
          loading={uploading}
          type="primary"
        >
          Upload
        </Button>
      </div>
    </Modal>
  );
};

export default FileUploadModal;
