import React from "react";
import { Button, Modal, Space, Typography } from "antd";
import * as Yup from "yup";

import "./index.scss";
import { ErrorMessage, Formik } from "formik";
import { Input } from "formik-antd";

const { Text } = Typography;

type PropTypes = {
  show: boolean;
  customFeedbackUrl?: string;
  titleEn?: string;
  titleDe?: string;
  titleFr?: string;
  titleIt?: string;
  handleClose: () => void;
  confirm: (
    customFeedbackUrl: string,
    titleEn: string,
    titleDe: string,
    titleFr: string,
    titleIt: string
  ) => Promise<void>;
};

const validationSchema = Yup.object().shape({
  customFeedbackUrl: Yup.string()
    .url("Should be valid URL")
    .required("Required"),
  titleEn: Yup.string().trim().required("Required"),
  titleDe: Yup.string().trim().required("Required"),
  titleFr: Yup.string().trim().required("Required"),
  titleIt: Yup.string().trim().required("Required"),
});

const CustomFeedbackModal: React.FC<PropTypes> = ({
  show,
  handleClose,
  confirm,
  customFeedbackUrl = "",
  titleEn = "",
  titleDe = "",
  titleFr = "",
  titleIt = "",
}) => {
  return (
    <Modal
      title="Update minimal price:"
      visible={show}
      onCancel={() => {
        handleClose();
      }}
      footer={null}
    >
      <div className="wrapper">
        <Formik
          validationSchema={validationSchema}
          initialValues={{
            customFeedbackUrl,
            titleEn,
            titleDe,
            titleFr,
            titleIt,
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            const { customFeedbackUrl, titleEn, titleDe, titleFr, titleIt } =
              values;
            await confirm(
              customFeedbackUrl,
              titleEn,
              titleDe,
              titleFr,
              titleIt
            );
            setSubmitting(true);
          }}
        >
          {({ isSubmitting, submitForm }) => (
            <Space direction="vertical" style={{ width: "100%" }}>
              <div className="inpLine">
                <Text strong>URL:</Text>
                <Input name="customFeedbackUrl" />
                <ErrorMessage
                  name="customFeedbackUrl"
                  component="p"
                  className="error"
                />
              </div>
              <div className="inpLine">
                <Text strong>Title EN:</Text>
                <Input name="titleEn" />
                <ErrorMessage name="titleEn" component="p" className="error" />
              </div>
              <div className="inpLine">
                <Text strong>Title DE:</Text>
                <Input name="titleDe" />
                <ErrorMessage name="titleDe" component="p" className="error" />
              </div>
              <div className="inpLine">
                <Text strong>Title FR:</Text>
                <Input name="titleFr" />
                <ErrorMessage name="titleFr" component="p" className="error" />
              </div>
              <div className="inpLine">
                <Text strong>Title IT:</Text>
                <Input name="titleIt" />
                <ErrorMessage name="titleIt" component="p" className="error" />
              </div>
              <Button
                type="primary"
                loading={isSubmitting}
                onClick={submitForm}
              >
                Update
              </Button>
            </Space>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default CustomFeedbackModal;
