import { CloseOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Popconfirm,
  Row,
  Space,
  Typography,
  message,
  Spin,
  DatePicker as AntDatePicker,
} from "antd";
import { ErrorMessage, Formik } from "formik";
import {
  Form,
  Input,
  DatePicker,
  Select,
  Switch,
  Slider,
  Radio,
  InputNumber,
} from "formik-antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import * as Yup from "yup";

import {
  IExpertiseArea,
  IInterpreterEditForm,
  ILanguage,
  VacationTime,
} from "../../utils/interfaces";
import InterpreterService from "../../utils/InterpreterService";
import LanguagesService from "../../utils/LanguagesService";
import ExpertiseService from "../../utils/ExpertiseService";
import FileService from "../../utils/FileService";
import { stripeCountries } from "../../utils/stripeCountries";
import { certificateUrl, statusProofUrl } from "../../helpers/urlHelpers";

import "./index.scss";
import FileUploadModal from "../../components/FileUploadModal";

const { Title, Text } = Typography;

const validationSchema = Yup.object().shape({
  firstName: Yup.string().trim().required("First name is required"),
  lastName: Yup.string().trim().required("Last name is required"),
  bornAt: Yup.string().required("Birthday is required."),
  gender: Yup.string().required("Gender is required"),
  country: Yup.string().required("Country is required."),
  city: Yup.string().required("City is required."),
  about: Yup.string().max(1000, "Maximum 1000 charecters allowed."),
  comments: Yup.string().max(2500, "Maximum 2500 charecters allowed."),
  street: Yup.string().required("Street is required."),
  postalCode: Yup.string().required("Postal code is required."),
  phone: Yup.string()
    .matches(
      /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/,
      // /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
      "Phone number is not valid"
    )
    .min(8, "Minimum 8 symbols")
    .required("Phone number is required"),
  email: Yup.string()
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Email is not valid"
    )
    .required("Email is required"),
  vatId: Yup.string(),
  chargeVat: Yup.boolean(),
  selfEmployed: Yup.boolean(),
  studyFields: Yup.string(),
  academicTitles: Yup.string(),
  employmentStatus: Yup.string(),
  workingLanguages: Yup.array()
    .of(
      Yup.object().shape({
        type: Yup.mixed().oneOf(["spoken", "sign"]).required("Required"),
        proficiency: Yup.mixed()
          .oneOf(["a1", "a2", "b1", "b2", "c1", "c2"])
          .required("Required"),
        code: Yup.string().min(1, "Languageis required").required("Required"),
      })
    )
    .required("Working languages is required"),
  expertiseAreas: Yup.array()
    .of(Yup.number())
    .min(1, "At least 1 area is required")
    .required("Expertise areas are required"),
  nativeLanguages: Yup.array()
    .of(Yup.string())
    .min(1, "At least 1 area is required")
    .required("Native languages are required"),
});

const EditInterpreter: React.FC = () => {
  const { id } = useParams();

  const [interpreter, setInterpreter] = useState<IInterpreterEditForm>();
  const [fetchingInterpreter, setFetchingInterpreter] = useState(false);
  const [spokenLanguages, setSpokenLanguages] = useState<ILanguage[]>([]);
  const [signLanguages, setSignLanguages] = useState<ILanguage[]>([]);
  const [vacationTimes, setVacationTimes] = useState<VacationTime[]>([]);
  const [expertiseAreas, setExpertiseAreas] = useState<IExpertiseArea[]>([]);
  const [fileUploadModalState, setFileUploadModalState] = useState({
    show: false,
    type: "",
  });
  const [from, setFrom] = useState<any>();
  const [to, setTo] = useState<any>();
  const addNewVacation = async () => {
    console.log(from, to, interpreter?.id);
    if (!from || !to || !interpreter) return;
    try {
      await InterpreterService.addVacation(interpreter.profileId, {
        start: from.format("YYYY-MM-DD"),
        end: to.format("YYYY-MM-DD"),
      });
      const fetchedVacations = await InterpreterService.getVacations(
        interpreter.profileId
      );
      setVacationTimes(fetchedVacations);
      setFrom(undefined);
      setTo(undefined);
    } catch (err: any) {
      message.error(err?.response?.data?.message ?? "Some error");
    }
  };
  const removeVacation = async (id: number) => {
    if (!interpreter) return;
    try {
      await InterpreterService.removeVacation(interpreter.profileId, id);
      setVacationTimes(vacationTimes.filter((v) => v.id !== id));
    } catch (err: any) {
      message.error(err?.response?.data?.message ?? "Some error");
    }
  };

  useEffect(() => {
    if (!id) return;

    const init = async () => {
      setFetchingInterpreter(true);
      const fetchedInterpreter = await InterpreterService.getInterpreter(id);
      const spoken = await LanguagesService.getSpokenLanguages();
      const sign = await LanguagesService.getSignLanguages();
      const fetchedAreas = await ExpertiseService.getExpertiseAreas();
      const fetchedVacations = await InterpreterService.getVacations(
        fetchedInterpreter.profileId
      );

      const {
        address,
        workingLanguages,
        nativeLanguages,
        expertiseAreas,
        ...rest
      } = fetchedInterpreter;
      const workinglanguagesToSet = workingLanguages.map((lang) => ({
        ...lang,
        type:
          lang.code.length === 2
            ? ("spoken" as "spoken" | "sign")
            : ("sign" as "spoken" | "sign"),
      }));

      const nativeLanguagesToSet = nativeLanguages.map((lang) => lang.code);

      const expertiseAreasToSet = expertiseAreas.map((ea) => ea.id);

      const interpreterToSet = {
        ...rest,
        ...address,
        workingLanguages: workinglanguagesToSet,
        nativeLanguages: nativeLanguagesToSet,
        expertiseAreas: expertiseAreasToSet,
        onboarded:
          fetchedInterpreter &&
            fetchedInterpreter.address &&
            fetchedInterpreter.languages &&
            fetchedInterpreter.workingLanguages &&
            fetchedInterpreter.certificates.length &&
            (fetchedInterpreter.statusProof ||
              fetchedInterpreter.employmentStatus) &&
            fetchedInterpreter.expertiseAreas.length &&
            fetchedInterpreter.certificates &&
            fetchedInterpreter.about
            ? true
            : false,
      };

      setInterpreter(interpreterToSet);
      setSpokenLanguages(spoken);
      setSignLanguages(sign);
      setExpertiseAreas(fetchedAreas);
      setFetchingInterpreter(false);
      setVacationTimes(fetchedVacations);
    };

    init();
  }, [id]);

  const formatter = (value: number | undefined) => {
    const price = ((value as number) * 1.17).toFixed(2);
    return `${value}% ${price}CHF`;
  };

  const sortLanguages = (a: ILanguage, b: ILanguage) => {
    if (a.title_en < b.title_en) return -1;
    if (a.title_en > b.title_en) return 1;
    return 0;
  };

  const getLanguageOptions = (type: string | undefined) => {
    if (type === "spoken")
      return spokenLanguages.sort(sortLanguages).map((lang) => (
        <Select.Option key={lang.code} value={lang.code}>
          {lang.title_en}
        </Select.Option>
      ));
    return signLanguages.sort(sortLanguages).map((lang) => (
      <Select.Option key={lang.code} value={lang.code}>
        {lang.title_en}
      </Select.Option>
    ));
  };

  const getExpertiseOptions = () => {
    return expertiseAreas.map((ea) => (
      <Select.Option key={ea.id} value={ea.id}>
        {ea.title_en}
      </Select.Option>
    ));
  };

  const updateInterpreter = async (data: any) => {
    await InterpreterService.updateInterpreter(data);
    message.success("Interpreter successfuly updated!");
  };

  const handleDeleteCertificate = async (certificateId: number) => {
    try {
      await FileService.deleteCertificate(
        certificateId,
        interpreter?.profileId as number
      );

      const certificatesToSet = interpreter?.certificates?.filter(
        (certificate) => certificate.id !== certificateId
      );

      setInterpreter({
        ...interpreter,
        certificates: certificatesToSet,
      } as IInterpreterEditForm);
      message.success("File was removed!");
    } catch (err) {
      console.log("err.response");
      console.log(err);
      message.error("Something went wrong!");
    }
  };

  const uplaodFile = async (file: File, type: string) => {
    if (!interpreter) return;

    try {
      if (fileUploadModalState.type === "certificate") {
        const response = await FileService.uploadCertificate(
          file,
          type,
          interpreter?.id
        );

        const certificatesToSet = interpreter.certificates?.slice();

        certificatesToSet?.push(response);

        setInterpreter({
          ...interpreter,
          certificates: certificatesToSet,
        });
      } else if (fileUploadModalState.type === "status-proof") {
        const response = await FileService.uploadStatusProof(
          file,
          interpreter?.profileId
        );

        setInterpreter({
          ...interpreter,
          statusProof: response,
        });
      }

      message.success("File successfully uploaded!");
    } catch {
      message.error("Something went wrong!");
    } finally {
      setFileUploadModalState({
        show: false,
        type: "",
      });
    }
  };

  const handleClose = () => {
    setFileUploadModalState({ show: false, type: "" });
  };

  if (fetchingInterpreter) {
    return (
      <Space size="middle">
        <Spin size="large" />
      </Space>
    );
  }

  return (
    <div>
      <Title>
        {`Editing: ${interpreter?.firstName} ${interpreter?.lastName}`}
      </Title>
      {interpreter?.onboarded && (
        <Formik
          initialValues={{
            ...interpreter,
          }}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={async (values, { setSubmitting, setFieldError }) => {
            setSubmitting(true);
            const valuesToSubmit = {
              ...values,
              bornAt: new Date(moment(values.bornAt).utc(true).toString()),
            };
            try {
              await updateInterpreter(valuesToSubmit);
            } catch (err: any) {
              if (err.response?.data?.statusCode === 400) {
                const [field, messageText] = err.response.data?.message[0]
                  .match(/^(\S+)\s(.*)/)
                  .slice(1);

                setFieldError(field, messageText);
              } else {
                message.error("Something went wrong");
              }
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({
            values,
            isSubmitting,
            setFieldTouched,
            setFieldValue,
            submitForm,
            validateForm,
          }) => (
            <Form>
              <Row gutter={16}>
                <Col span={12}>
                  <div className="inputGroup">
                    <Text strong>Comments:</Text>
                    <Input.TextArea
                      name="comments"
                      rows={5}
                      style={{ resize: "none" }}
                    />
                    <ErrorMessage
                      name="comments"
                      component="p"
                      className="error"
                    />
                  </div>
                  <div className="inputGroup">
                    <Text strong>First Name:</Text>
                    <Input name="firstName" />
                    <ErrorMessage
                      name="firstName"
                      component="p"
                      className="error"
                    />
                  </div>
                  <div className="inputGroup">
                    <Text strong>Last Name:</Text>
                    <Input name="lastName" />
                    <ErrorMessage
                      name="lastName"
                      component="p"
                      className="error"
                    />
                  </div>
                  <div className="inputGroup">
                    <Text strong>Phone:</Text>
                    <Input name="phone" />
                    <ErrorMessage
                      name="phone"
                      component="p"
                      className="error"
                    />
                  </div>
                  <div className="inputGroup">
                    <Text strong>Email:</Text>
                    <Input name="email" />
                    <ErrorMessage
                      name="email"
                      component="p"
                      className="error"
                    />
                  </div>
                  <div className="inputGroup">
                    <Text strong>Date of Birth:</Text>
                    <DatePicker
                      name="bornAt"
                      format="DD/MM/YYYY"
                      suffixIcon=""
                      placeholder="dd/mm/yyyy"
                      onChange={(e) => console.log(e)}
                      defaultPickerValue={moment().subtract(18, "year")}
                    />
                    <ErrorMessage
                      name="bornAt"
                      component="p"
                      className="error"
                    />
                  </div>
                  <div className="inputGroup">
                    <Text strong>Gender:</Text>
                    <Select name="gender" style={{ width: 120 }}>
                      <Select.Option value="male">Male</Select.Option>
                      <Select.Option value="female">Female</Select.Option>
                      <Select.Option value="diverse">Diverse</Select.Option>
                    </Select>
                    <ErrorMessage
                      name="bornAt"
                      component="p"
                      className="error"
                    />
                  </div>
                  <div className="inputGroup">
                    <Text strong>Description:</Text>
                    <Input.TextArea
                      name="about"
                      rows={5}
                      style={{ resize: "none" }}
                    />
                    <ErrorMessage
                      name="about"
                      component="p"
                      className="error"
                    />
                  </div>
                  <div className="sliderGroup">
                    <Text strong>Price:</Text>
                    <Row style={{ maxWidth: 300 }}>
                      <Col span={18}>
                        <Slider
                          min={20}
                          max={80}
                          name="price"
                          tipFormatter={formatter}
                        />
                      </Col>
                      <Col span={6}>
                        <InputNumber
                          min={20}
                          max={80}
                          style={{ maxWidth: 60, marginLeft: 15 }}
                          name="price"
                        />
                      </Col>
                    </Row>
                    <ErrorMessage
                      name="price"
                      component="p"
                      className="error"
                    />
                  </div>
                  <div className="switchGroup">
                    <Text strong>Charge VAT:</Text>
                    <Switch name="chargeVat" />
                    <ErrorMessage
                      name="chargeVat"
                      component="p"
                      className="error"
                    />
                  </div>
                  <div className="switchGroup">
                    <Text strong>Self Employed:</Text>
                    <Switch name="selfEmployed" />
                    <ErrorMessage
                      name="selfEmployed"
                      component="p"
                      className="error"
                    />
                  </div>
                  <div className="switchGroup">
                    <Text strong>Academic Degree:</Text>
                    <Switch name="academicDegree" />
                    <ErrorMessage
                      name="academicDegree"
                      component="p"
                      className="error"
                    />
                  </div>
                  <div className="nativeLanguages">
                    <Space direction="vertical" style={{ alignItems: "start" }}>
                      <Text strong>Native Languages:</Text>
                      <Select
                        name="nativeLanguages"
                        style={{ width: 300 }}
                        mode="multiple"
                      >
                        {getLanguageOptions("spoken")}
                      </Select>
                      <ErrorMessage
                        name="nativeLanguages"
                        component="p"
                        className="error"
                      />
                    </Space>
                  </div>
                  <div className="expertiseAreas">
                    <Space direction="vertical" style={{ alignItems: "start" }}>
                      <Text strong>Expertise Areas:</Text>
                      <Select
                        name="expertiseAreas"
                        style={{ width: 300 }}
                        mode="multiple"
                      >
                        {getExpertiseOptions()}
                      </Select>
                      <ErrorMessage
                        name="expertiseAreas"
                        component="p"
                        className="error"
                      />
                    </Space>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="inputGroup">
                    <Text strong>Country:</Text>
                    <Select name="country" style={{ width: 300 }}>
                      {Object.keys(stripeCountries).map((country) => (
                        <Select.Option
                          key={country}
                          value={country.toLowerCase()}
                        >
                          {(stripeCountries as any)[country]}
                        </Select.Option>
                      ))}
                    </Select>
                    <ErrorMessage
                      name="bornAt"
                      component="p"
                      className="error"
                    />
                  </div>
                  <div className="inputGroup">
                    <Text strong>City:</Text>
                    <Input name="city" />
                    <ErrorMessage name="city" component="p" className="error" />
                  </div>
                  <div className="inputGroup">
                    <Text strong>Street:</Text>
                    <Input name="street" />
                    <ErrorMessage
                      name="street"
                      component="p"
                      className="error"
                    />
                  </div>
                  <div className="inputGroup">
                    <Text strong>Postal Code:</Text>
                    <Input name="postalCode" />
                    <ErrorMessage
                      name="postalCode"
                      component="p"
                      className="error"
                    />
                  </div>
                  <div className="inputGroup">
                    <Text strong>VAT ID:</Text>
                    <Input name="vatId" />
                    <ErrorMessage
                      name="vatId"
                      component="p"
                      className="error"
                    />
                  </div>
                  <div className="inputGroup">
                    <Text strong>Field(s) of study:</Text>
                    <Input name="studyFields" />
                    <ErrorMessage
                      name="studyFields"
                      component="p"
                      className="error"
                    />
                  </div>
                  <div className="inputGroup">
                    <Text strong>Academic Titles:</Text>
                    <Input name="academicTitles" />
                    <ErrorMessage
                      name="academicTitles"
                      component="p"
                      className="error"
                    />
                  </div>
                  <div className="inputGroup">
                    <Text strong>Employment Status:</Text>
                    <Input.TextArea
                      name="employmentStatus"
                      rows={5}
                      style={{ resize: "none" }}
                    />
                    <ErrorMessage
                      name="employmentStatus"
                      component="p"
                      className="error"
                    />
                  </div>
                  <div className="workingLanguages">
                    <Space direction="vertical" style={{ alignItems: "start" }}>
                      <Text strong>Working Languages:</Text>
                      {values.workingLanguages?.map((lang, index) => {
                        return (
                          <div key={index}>
                            <Text>Working Language {index + 1}</Text>
                            <Popconfirm
                              title="Remove this language?"
                              cancelText="No"
                              okText="Yes"
                              onConfirm={() => {
                                values.workingLanguages?.splice(index, 1);
                                validateForm();
                              }}
                            >
                              <Button
                                type="primary"
                                danger
                                size="small"
                                shape="circle"
                                className="removeBtn"
                                icon={<CloseOutlined />}
                              />
                            </Popconfirm>
                            <div className="languageType">
                              <Text>Language type:</Text>
                              <Radio.Group
                                name={`workingLanguages[${index}].type`}
                                onChange={() => {
                                  setFieldTouched(
                                    `workingLanguages[${index}].code`,
                                    true
                                  );
                                  setFieldValue(
                                    `workingLanguages[${index}].code`,
                                    ""
                                  );
                                }}
                              >
                                <Radio
                                  name={`workingLanguages[${index}].type`}
                                  value="spoken"
                                >
                                  Spoken language
                                </Radio>
                                <Radio
                                  name={`workingLanguages[${index}].type`}
                                  value="sign"
                                >
                                  Sign language
                                </Radio>
                              </Radio.Group>
                              <ErrorMessage
                                name={`workingLanguages[${index}].type`}
                                component="p"
                                className="error"
                              />
                            </div>
                            <Select
                              name={`workingLanguages[${index}].code`}
                              style={{ width: 300 }}
                              placeholder="Please, select language"
                              showSearch
                              filterOption={(input, option: any) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {getLanguageOptions(
                                values.workingLanguages?.[index].type
                              )}
                            </Select>
                            <ErrorMessage
                              name={`workingLanguages[${index}].code`}
                              component="p"
                              className="error"
                            />

                            <div className="languageType">
                              <Text>Proficiency:</Text>
                              <Radio.Group
                                name={`workingLanguages[${index}].proficiency`}
                              >
                                <Radio
                                  name={`workingLanguages[${index}].proficiency`}
                                  value="a1"
                                >
                                  A1
                                </Radio>
                                <Radio
                                  name={`workingLanguages[${index}].proficiency`}
                                  value="a2"
                                >
                                  A2
                                </Radio>
                                <Radio
                                  name={`workingLanguages[${index}].proficiency`}
                                  value="b1"
                                >
                                  B1
                                </Radio>
                                <Radio
                                  name={`workingLanguages[${index}].proficiency`}
                                  value="b2"
                                >
                                  B2
                                </Radio>
                                <Radio
                                  name={`workingLanguages[${index}].proficiency`}
                                  value="c1"
                                >
                                  C1
                                </Radio>
                                <Radio
                                  name={`workingLanguages[${index}].proficiency`}
                                  value="c2"
                                >
                                  C2
                                </Radio>
                              </Radio.Group>
                              <ErrorMessage
                                name={`workingLanguages[${index}].proficiency`}
                                component="p"
                                className="error"
                              />
                            </div>

                            <hr />
                          </div>
                        );
                      })}
                      <div className="addLanguage">
                        <Button
                          type="default"
                          onClick={() => {
                            values.workingLanguages?.push({
                              code: "",
                              proficiency: undefined,
                              type: "spoken",
                            });
                            validateForm();
                          }}
                        >
                          Add new
                        </Button>
                      </div>
                    </Space>
                  </div>
                </Col>
              </Row>
              <Button
                className="saveBtn"
                onClick={submitForm}
                disabled={isSubmitting}
                loading={isSubmitting}
                type="primary"
              >
                Save changes
              </Button>
            </Form>
          )}
        </Formik>
      )}

      <Row style={{ marginTop: 30 }}>
        <Col span={12} className="columnView">
          <Space
            direction="vertical"
            style={{ alignItems: "start", textAlign: "start" }}
          >
            <Title level={3}>Certifications, diplomas & references:</Title>
            {interpreter?.certificates?.map((certificate, i) => (
              <div key={certificate.id} className="cerificateWrapper">
                <a
                  href={certificateUrl(certificate.name)}
                  target="_blank"
                  rel="noreferrer"
                  key={certificate.id}
                >
                  <div className="certificate">
                    <Text className="number" strong>
                      {i + 1}.
                    </Text>
                    <div>
                      <div>{certificate.name}</div>
                      <Text type="secondary">type: {certificate.type}</Text>
                    </div>
                  </div>
                </a>
                <Popconfirm
                  title="Remove this file?"
                  cancelText="No"
                  okText="Yes"
                  onConfirm={() => {
                    handleDeleteCertificate(certificate.id);
                  }}
                >
                  <Button
                    type="primary"
                    danger
                    size="small"
                    shape="circle"
                    className="removeBtn"
                    icon={<CloseOutlined />}
                  />
                </Popconfirm>
              </div>
            ))}
            <Button
              className="uploadBtn"
              onClick={() =>
                setFileUploadModalState({
                  show: true,
                  type: "certificate",
                })
              }
              type="primary"
            >
              Upload new file
            </Button>
          </Space>
        </Col>
        <Col span={12} className="columnView">
          <Space
            direction="vertical"
            style={{ alignItems: "start", textAlign: "start" }}
          >
            <Title level={3}>Status Proof:</Title>
            <div className="statusProofWrapper">
              <a
                href={statusProofUrl(interpreter?.statusProof?.name as string)}
                target="_blank"
                rel="noreferrer"
              >
                <div>
                  <div>
                    <div>{interpreter?.statusProof?.name}</div>
                  </div>
                </div>
              </a>
            </div>
            <Button
              className="updloadStatusProofBtn"
              onClick={() =>
                setFileUploadModalState({
                  show: true,
                  type: "status-proof",
                })
              }
              type="primary"
            >
              Upload new file
            </Button>
          </Space>
        </Col>
      </Row>

      <Row style={{ marginTop: 30, marginBottom: 150 }}>
        <Col span={12} className="columnView">
          <Space
            direction="vertical"
            style={{ alignItems: "start", textAlign: "start" }}
          >
            <Title level={3}>Vacation times:</Title>
            <div className="addVacationTime">
              <div className="pickerWrap">
                <p className="title">From</p>
                <AntDatePicker
                  name="bornAt"
                  format="DD/MM/YYYY"
                  suffixIcon={false}
                  onSelect={(v) => setFrom(v)}
                  value={from}
                />
              </div>
              <div className="pickerWrap">
                <p className="title">To</p>
                <AntDatePicker
                  name="bornAt"
                  format="DD/MM/YYYY"
                  suffixIcon={false}
                  onSelect={(v) => setTo(v)}
                  value={to}
                />
              </div>
              <Button
                className="blue-btn"
                disabled={!from || !to}
                onClick={addNewVacation}
              >
                Save
              </Button>
            </div>
            {vacationTimes.map((v, i) => (
              <div className="vacationTime">
                <p>
                  <b>{++i}.</b> {v.startDate} — {v.endDate}
                </p>

                <Popconfirm
                  title="Remove this vacation?"
                  cancelText="No"
                  okText="Yes"
                  onConfirm={() => {
                    removeVacation(v.id);
                  }}
                >
                  <Button
                    type="primary"
                    danger
                    size="small"
                    shape="circle"
                    className="removeBtn"
                    icon={<CloseOutlined />}
                  />
                </Popconfirm>
              </div>
            ))}
          </Space>
        </Col>
      </Row>

      <FileUploadModal
        status={fileUploadModalState}
        handleClose={handleClose}
        confirm={uplaodFile}
      />
    </div>
  );
};

export default EditInterpreter;
