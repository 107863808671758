import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "antd";
import { ErrorMessage, Formik, FormikProps } from "formik";
import Text from "antd/lib/typography/Text";
import * as Yup from "yup";
import CompanyService from "../../../utils/CompanyService";

import { Form, Select } from "formik-antd";
import { IUser } from "../../../utils/interfaces";

const validationSchema = Yup.object().shape({
  description: Yup.string().trim(),
});

type PropTypes = {
  show: boolean;
  companyId: number;
  handleClose: () => void;
  confirm: (
    value: string,
    setSubmitting: (isSubmitting: boolean) => void,
    clearState: () => void
  ) => void;
};

const UpdateVideosessionCreator: React.FC<PropTypes> = ({
  show,
  companyId,
  handleClose,
  confirm,
}) => {
  const formikRef = useRef<FormikProps<{ creatorId: string }>>(null);
  const [creators, setCreators] = useState<IUser[]>([]);

  useEffect(() => {
    if (show && !creators.length && companyId) {
      const intiUsers = async () => {
        const fetchedCreators = await CompanyService.getCompanyUsers(companyId);
        setCreators(fetchedCreators);
      };

      intiUsers();
    }
  }, [companyId, creators, show]);

  return (
    <Modal
      title="Update videosession creator:"
      visible={show}
      onCancel={() => {
        handleClose();
        formikRef.current?.resetForm();
      }}
      footer={null}
    >
      <div className="createVoucher">
        <Formik
          innerRef={formikRef}
          initialValues={{
            creatorId: "",
          }}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={(values, { setSubmitting, resetForm }) => {
            confirm(values.creatorId, setSubmitting, resetForm);
          }}
        >
          {({ isSubmitting, submitForm }) => (
            <Form>
              <div className="dateGroup">
                <Text strong>Change creator:</Text>
                <Select
                  name="creatorId"
                  showSearch
                  style={{ width: 300 }}
                  filterOption={(input, option) =>
                    !!(option?.label as string)
                      ?.toLowerCase()
                      .startsWith(input.toLowerCase())
                  }
                >
                  {creators.map((user) => (
                    <Select.Option value={user.id} label={user.email}>
                      "{user.email}" {user.firstName} {user.lastName}
                    </Select.Option>
                  ))}
                </Select>

                <ErrorMessage name="value" component="p" className="error" />
              </div>
              <Button
                className="saveBtn"
                onClick={submitForm}
                disabled={isSubmitting}
                loading={isSubmitting}
                type="primary"
              >
                Update
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default UpdateVideosessionCreator;
