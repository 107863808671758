import React from "react";
import { Button, Modal, Tooltip } from "antd";

import { LinkOutlined } from "@ant-design/icons";
import { registerEmployeeUrl } from "../../helpers/urlHelpers";

import "./index.scss";

type PropTypes = {
  show: boolean;
  data: {
    email: string;
    code: string;
  };
  handleClose: () => void;
  confirm: () => void;
};

const InviteEmployee: React.FC<PropTypes> = ({
  show,
  data: { email, code },
  handleClose,
  confirm,
}) => {
  return (
    <Modal
      title="The link has been generated successfully!"
      visible={show}
      onCancel={handleClose}
      footer={null}
    >
      <div>
        <p>
          The link to the platform has been generated successfully! Press “Send”
          and the link will go to {email}
        </p>
        <div className="linkGroup">
          <LinkOutlined />
          <p>{registerEmployeeUrl(code, email)}</p>
          <Tooltip title="Copied!" trigger="click">
            <Button
              type="primary"
              onClick={() =>
                navigator.clipboard.writeText(registerEmployeeUrl(code, email))
              }
            >
              Copy
            </Button>
          </Tooltip>
          ,
        </div>

        <div className="footerBtnGroup">
          <Button
            onClick={() => {
              handleClose();
            }}
          >
            Cancel
          </Button>
          <Button onClick={confirm} type="primary">
            Send
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default InviteEmployee;
