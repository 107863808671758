import React, { createContext, useContext, useEffect, useState } from "react";
import AdminService from "../utils/AdminService";
import { ILoginData, IUser } from "../utils/interfaces";

interface AuthContextType {
  user: IUser | undefined;
  signin: (data: ILoginData, callback: (err?: any) => void) => void;
  signout: (callback: (err?: any) => void) => void;
}
const AuthContext = createContext<AuthContextType>(null!);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<IUser>();
  useEffect(() => {
    if (!user && AdminService.tokenExist()) {
      const checkUser = async () => {
        try {
          const fetchedUser = await AdminService.getMe();
          setUser(fetchedUser);
        } catch {}
      };
      checkUser();
    }
  }, [user]);

  const signin = async (data: ILoginData, callback: (err?: any) => void) => {
    try {
      await AdminService.signin(data);
      const fetchedUser = await AdminService.getMe();
      if (fetchedUser.role !== "admin") {
        AdminService.logout();
        return callback("Invalid credentials!");
      }
      setUser(fetchedUser);
      callback();
    } catch (err) {
      callback(err);
    }
  };

  const signout = (callback: (err?: any) => void) => {
    AdminService.logout();
    setUser(undefined);
    callback();
  };

  const value = { user, signin, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
