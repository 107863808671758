import React, { useRef } from "react";
import { Button, Modal } from "antd";
import { ErrorMessage, Formik, FormikProps } from "formik";
import { DatePicker, Form } from "formik-antd";
import Text from "antd/lib/typography/Text";
import * as Yup from "yup";
import moment, { Moment } from "moment";

const validationSchema = Yup.object().shape({
  validBefore: Yup.string().trim().required("Valid before date is required."),
});

type PropTypes = {
  show: boolean;
  handleClose: () => void;
  confirm: (
    validBefore: string,
    setSubmitting: (isSubmitting: boolean) => void,
    clearState: () => void
  ) => void;
};

const ActivateVoucher: React.FC<PropTypes> = ({
  show,
  handleClose,
  confirm,
}) => {
  const formikRef = useRef<FormikProps<{ validBefore: string }>>(null);
  const disabledDate = (current: Moment) =>
    current && current < moment().endOf("day");

  const disabledMinutes = () => {
    const minutes = [];
    for (let i: number = 0; i < 60; i++) {
      if (i % 15 !== 0 && i !== 59) {
        minutes.push(i);
      }
    }

    return minutes;
  };

  return (
    <Modal
      title="Activate voucher:"
      visible={show}
      onCancel={() => {
        handleClose();
        formikRef.current?.resetForm();
      }}
      footer={null}
    >
      <div className="createVoucher">
        <Formik
          innerRef={formikRef}
          initialValues={{
            validBefore: "",
          }}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={(values, { setSubmitting, resetForm }) => {
            confirm(values.validBefore, setSubmitting, resetForm);
          }}
        >
          {({ isSubmitting, submitForm }) => (
            <Form>
              <div className="dateGroup">
                <Text strong>Valid before:</Text>
                <DatePicker
                  name="validBefore"
                  format="DD/MM/YYYY HH:mm"
                  placeholder="dd/mm/yyyy hh:mm"
                  disabledDate={disabledDate}
                  disabledMinutes={disabledMinutes}
                  showTime={{
                    hideDisabledOptions: true,
                    defaultValue: moment("00:00:00", "HH:mm:ss"),
                  }}
                />
                <ErrorMessage
                  name="validBefore"
                  component="p"
                  className="error"
                />
              </div>
              <Button
                className="saveBtn"
                onClick={submitForm}
                disabled={isSubmitting}
                loading={isSubmitting}
                type="primary"
              >
                Activate
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default ActivateVoucher;
