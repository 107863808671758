import React, { useRef } from "react";
import { Button, Modal } from "antd";
import { ErrorMessage, Formik, FormikProps } from "formik";
import { DatePicker, Form } from "formik-antd";
import Text from "antd/lib/typography/Text";
import * as Yup from "yup";
import moment from "moment";

const validationSchema = Yup.object().shape({
  date: Yup.string().trim().required("Date is required."),
});

type PropTypes = {
  show: boolean;
  handleClose: () => void;
  confirm: (
    date: string,
    setSubmitting: (isSubmitting: boolean) => void,
    clearState: () => void
  ) => void;
};

const MarkSpending: React.FC<PropTypes> = ({ show, handleClose, confirm }) => {
  const formikRef = useRef<FormikProps<{ date: string }>>(null);

  return (
    <Modal
      title="Mark as paid:"
      visible={show}
      onCancel={() => {
        handleClose();
        formikRef.current?.resetForm();
      }}
      footer={null}
    >
      <div className="createVoucher">
        <Formik
          innerRef={formikRef}
          initialValues={{
            date: "",
          }}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={(values, { setSubmitting, resetForm }) => {
            confirm(values.date, setSubmitting, resetForm);
          }}
        >
          {({ isSubmitting, submitForm }) => (
            <Form>
              <div className="dateGroup">
                <Text strong>Date and time when spending was paid:</Text>
                <DatePicker
                  name="date"
                  format="DD/MM/YYYY HH:mm"
                  placeholder="dd/mm/yyyy hh:mm"
                  minuteStep={5}
                  showNow={false}
                  showTime={{
                    defaultValue: moment("00:00:00", "HH:mm:ss"),
                  }}
                />
                <ErrorMessage name="date" component="p" className="error" />
              </div>
              <Button
                className="saveBtn"
                onClick={submitForm}
                disabled={isSubmitting}
                loading={isSubmitting}
                type="primary"
              >
                Update
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default MarkSpending;
