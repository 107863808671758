const additionalFields = [
  {
    name: 'caseNumber',
    placeholder: {
      en: 'Enter case number',
      de: 'Fallnummer eingeben',
      fr: 'Saisir le numéro de cas',
      it: 'Inserisci il numero del caso',
    },
    title: {
      en: 'Case number',
      de: 'Fallnummer',
      fr: 'Numéro de cas',
      it: 'Numero del caso',
    },
    required: false,
  },
  {
    name: 'patientId',
    placeholder: {
      en: 'Enter patient ID',
      de: 'Patienten-ID eingeben',
      fr: "Saisir l'ID du patient",
      it: "Inserisci l'ID del paziente",
    },
    title: {
      en: 'Patient ID',
      de: 'Patienten-ID',
      fr: 'ID du patient',
      it: 'ID del paziente',
    },
    required: false,
  },
  {
    name: 'patientName',
    placeholder: {
      en: 'Enter patient initials',
      de: 'Patienten-Initialen eingeben',
      fr: 'Saisir les initiales du patient',
      it: 'Inserisci le iniziali del paziente',
    },
    title: {
      en: 'Patient initials',
      de: 'Patienten-Initialen',
      fr: 'Initiales du patient',
      it: 'Iniziali del paziente',
    },
    required: false,
  },
  {
    name: 'patientBirth',
    placeholder: {
      en: 'Patient date of birth',
      de: 'Geburtsdatum Patient',
      fr: 'Date de naissance du patient',
      it: 'Data di nascita del paziente',
    },
    title: {
      en: 'Patient date of birth',
      de: 'Geburtsdatum Patient',
      fr: 'Date de naissance du patient',
      it: 'Data di nascita del paziente',
    },
    required: false,
  },
  {
    name: 'patientType',
    placeholder: {
      en: 'Select patient type',
      de: 'Patiententyp wählen',
      fr: 'Choisissez le type de patient',
      it: 'Seleziona il tipo di paziente',
    },
    title: {
      en: 'Patient type',
      de: 'Patiententyp',
      fr: 'Type de patient',
      it: 'Tipo di paziente',
    },
    options: [
      {
        en: 'outpatient',
        de: 'ambulant',
        fr: 'ambulatoire',
        it: 'ambulante',
      },
      {
        en: 'inpatient',
        de: 'stationär',
        fr: 'hospitalier',
        it: 'ricovero',
      },
    ],
    required: false,
  },
];

export default additionalFields;
