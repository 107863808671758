import ApiService from "./ApiService";
import { Feedback, IFilterItems } from "./interfaces";

class FeedbackService extends ApiService {
  public async getFeedbacks({
    current,
    pageSize,
    companyName,
    interpreterEmail,
  }: IFilterItems): Promise<{ items: Feedback[]; count: number }> {
    const offset = ((current as number) - 1) * (pageSize as number);
    let url = `feedback?offset=${offset}`;
    if (companyName) url += `&companyName=${companyName}`;
    if (interpreterEmail) url += `&interpreterEmail=${interpreterEmail}`;

    const response = await this.request({
      url,
    });

    return response.data;
  }

  public async getFeedbackDetails(id: number): Promise<Feedback> {
    const response = await this.request({
      url: `feedback/${id}`,
    });

    return response.data;
  }
}

const instance = new FeedbackService();

export default instance;
