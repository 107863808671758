import ApiService from "./ApiService";
import { ILoginData, ITokens, IUser } from "./interfaces";

class AdminService extends ApiService {
  public async signin(data: ILoginData): Promise<ITokens> {
    const { data: fetchedData } = await this.request<ITokens>({
      url: "/auth/signin",
      method: "post",
      data,
    });
    const { accessToken, refreshToken, notificationSecret } =
      fetchedData as ITokens;
    this.setToken(accessToken, refreshToken, notificationSecret);
    return { accessToken, refreshToken, notificationSecret };
  }

  public async getMe(): Promise<IUser> {
    const response = await this.request<IUser>({
      url: "/auth/me",
    });

    return response.data;
  }

  public tokenExist(): boolean {
    const { token } = this.getConfigs();
    return !!token;
  }

  public logout(): void {
    return this.deleteTokens();
  }

  public async verifyMe(): Promise<string> {
    const response = await this.request<string>({
      url: "/auth/admin-verification",
    });

    return response.data;
  }
}

const instance = new AdminService();

export default instance;
