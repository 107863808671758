import React, { useEffect, useState } from "react";
import { Col, Modal, Row, Space, Spin, Typography } from "antd";
import { IVoucher } from "../../../utils/interfaces";
import VoucherService from "../../../utils/VoucherService";
import { transformPrice } from "../../../helpers/transform";

import "./index.scss";
import { Link } from "react-router-dom";
import { DoubleRightOutlined } from "@ant-design/icons";

const { Text, Title } = Typography;

type PropTypes = {
  show: boolean;
  id: number;
  handleClose: () => void;
};

const VoucherDetails: React.FC<PropTypes> = ({ show, id, handleClose }) => {
  const [voucher, setVoucher] = useState<IVoucher>({
    bonusAmount: 0,
    validBefore: "",
    code: "",
    maxRedeems: 0,
  });
  const [loadingVoucher, setLoadingVoucher] = useState(true);

  const getVoucherDetails = async (id: number) => {
    setLoadingVoucher(true);
    const response = await VoucherService.getVoucherDetails(id);

    setVoucher(response);

    setLoadingVoucher(false);
  };

  useEffect(() => {
    if (id && id !== voucher?.id) getVoucherDetails(id);
  }, [id, voucher]);

  return (
    <Modal
      title="Voucher Details:"
      visible={show}
      onCancel={handleClose}
      footer={null}
      className="voucherDetails"
    >
      {loadingVoucher ? (
        <Spin className="spinner" size="large" />
      ) : (
        <div>
          <Row>
            <Col span={12}>
              <Space direction="vertical" style={{ alignItems: "start" }}>
                <Text>
                  Code: <Text strong>{voucher?.code}</Text>
                </Text>
                <Text>
                  Bonus amount:{" "}
                  <Text strong>{transformPrice(voucher?.bonusAmount)} CHF</Text>
                </Text>
              </Space>
            </Col>
            <Col span={12}>
              <Space direction="vertical" style={{ alignItems: "start" }}>
                <Text>
                  Valid Before:{" "}
                  <Text strong>
                    {new Date(voucher.validBefore).toLocaleString()}
                  </Text>
                </Text>
                <Text>
                  Max Redeems: <Text strong>{voucher.maxRedeems}</Text>
                </Text>
              </Space>
            </Col>
          </Row>
          {!!voucher.redeems?.length && (
            <Row className="companys">
              <Title level={5}>Companys:</Title>
              <div className="companysList">
                {voucher.redeems?.map((redeem, index) => (
                  <Space direction="vertical" className="companyInfo">
                    <Text strong>
                      {index + 1}. {redeem.company.name}{" "}
                      <Link
                        className="detailsLink"
                        to={`/admin/companies/${redeem.company.id}`}
                      >
                        <DoubleRightOutlined />
                      </Link>
                    </Text>
                    <Text>
                      Bonus Balance:{" "}
                      <Text strong>
                        {transformPrice(redeem.company.bonusBalance)} CHF
                      </Text>
                    </Text>
                    <Text>
                      Outstanding balance:{" "}
                      <Text strong>
                        {transformPrice(redeem.company.outstandingBalance)} CHF
                      </Text>
                    </Text>
                  </Space>
                ))}
              </div>
            </Row>
          )}
        </div>
      )}
    </Modal>
  );
};

export default VoucherDetails;
