import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export const useQuery = (query: string) => {
  const [searchParams] = useSearchParams();
  const [urlQuery, setUrlQuery] = useState<string | null>(null);

  useEffect(() => {
    const value = searchParams.get(query);
    setUrlQuery(value);
  }, [searchParams, query]);

  return urlQuery;
};

// hook from react-router-dom docs

// import React, { useMemo } from "react";
// import { useLocation } from "react-router";

// export function useQuery() {
//   const { search } = useLocation();

//   return useMemo(() => new URLSearchParams(search), [search]);
// }
