import React, { useEffect, useState } from "react";
import { Button, Modal, Select, Typography } from "antd";
import VoucherService from "../../../utils/VoucherService";
import { transformPrice } from "../../../helpers/transform";

import "./index.scss";

const { Option } = Select;

type PropTypes = {
  show: boolean;
  handleClose: () => void;
  confirm: (code: string) => void;
};

const UseVoucher: React.FC<PropTypes> = ({ show, handleClose, confirm }) => {
  const [options, setOptions] = useState<any>([]);
  const [voucher, setVoucher] = useState<undefined | string>();

  useEffect(() => {
    if (show) getVouchers();
  }, [show]);

  const getVouchers = async () => {
    const response = await VoucherService.getVouchers({
      current: 0,
      pageSize: 0,
    });

    const optionsToSet = response.vouchers.map((voucher) => (
      <Option value={voucher.code}>
        <Typography.Text strong>
          {voucher.code} - {transformPrice(voucher.bonusAmount)} CHF
        </Typography.Text>
      </Option>
    ));

    setOptions(optionsToSet);
  };

  const handleConfirm = () => {
    if (!voucher) return;

    confirm(voucher);
  };
  return (
    <Modal
      title="Activate voucher:"
      visible={show}
      onCancel={() => {
        setVoucher(undefined);
        handleClose();
      }}
      footer={null}
    >
      <div className="activateVoucher">
        <Select
          placeholder="Select voucher"
          value={voucher}
          onChange={setVoucher}
          style={{ width: 240 }}
        >
          {options}
        </Select>

        <Button disabled={!voucher} onClick={handleConfirm} type="primary">
          Activate
        </Button>
      </div>
    </Modal>
  );
};

export default UseVoucher;
