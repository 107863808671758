import { Col, Row } from "antd";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../../hooks/authHook";
import "./index.scss";

const AuthLayout = ({ children }: { children: JSX.Element }) => {
  const auth = useAuth();
  const location = useLocation();
  const from: any = ((location as any).state as any)?.from || "/admin";

  if (auth.user) {
    return <Navigate to={from} state={{ from: location }} />;
  }

  return (
    <Row justify="center" align="middle" className="auth-wrapper">
      <Col span={10}>{children}</Col>
    </Row>
  );
};

export default AuthLayout;
