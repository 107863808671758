import React, { useRef } from "react";
import { Button, Modal } from "antd";
import { ErrorMessage, Formik, FormikProps } from "formik";
import { DatePicker, Form, Input } from "formik-antd";
import Text from "antd/lib/typography/Text";
import * as Yup from "yup";
import moment, { Moment } from "moment";

import "./index.scss";
import { IVoucher } from "../../../utils/interfaces";

const validationSchema = Yup.object().shape({
  code: Yup.string()
    .trim()
    .matches(/^[a-z0-9]+$/i, "Should include only letters and number")
    .min(4, "Minimum 4 characters.")
    .required("Code is required."),
  validBefore: Yup.string().trim().required("Valid before date is required."),
  maxRedeems: Yup.number()
    .min(1, "Minimum 1")
    .required("Maximum number of uses is required."),
  bonusAmount: Yup.number().min(1, "Bonus amount is required"),
});

type PropTypes = {
  show: boolean;
  handleClose: () => void;
  confirm: (
    values: IVoucher,
    setSubmitting: (isSubmitting: boolean) => void,
    clearState: () => void
  ) => void;
};

const CreateVoucherModal: React.FC<PropTypes> = ({
  show,
  handleClose,
  confirm,
}) => {
  const formikRef = useRef<FormikProps<IVoucher>>(null);
  const disabledDate = (current: Moment) =>
    current && current < moment().endOf("day");

  const disabledMinutes = () => {
    const minutes = [];
    for (let i: number = 0; i < 60; i++) {
      if (i % 15 !== 0 && i !== 59) {
        minutes.push(i);
      }
    }

    return minutes;
  };

  return (
    <Modal
      title="Create new voucher:"
      visible={show}
      onCancel={() => {
        handleClose();
        formikRef.current?.resetForm();
      }}
      footer={null}
    >
      <div className="createVoucher">
        <Formik
          innerRef={formikRef}
          initialValues={{
            code: "",
            validBefore: "",
            bonusAmount: 50,
            maxRedeems: 1,
          }}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={(values, { setSubmitting, resetForm }) => {
            confirm(
              { ...values, bonusAmount: values.bonusAmount * 100 },
              setSubmitting,
              resetForm
            );
          }}
        >
          {({ isSubmitting, setFieldValue, submitForm }) => (
            <Form>
              <div className="inputGroup">
                <Text strong>Code:</Text>
                <Input
                  onChange={(e) =>
                    setFieldValue("code", e.target.value.toUpperCase())
                  }
                  name="code"
                />
                <ErrorMessage name="code" component="p" className="error" />
              </div>
              <div className="dateGroup">
                <Text strong>Valid before:</Text>
                <DatePicker
                  name="validBefore"
                  format="DD/MM/YYYY HH:mm"
                  placeholder="dd/mm/yyyy hh:mm"
                  disabledDate={disabledDate}
                  disabledMinutes={disabledMinutes}
                  showTime={{
                    hideDisabledOptions: true,
                    defaultValue: moment("00:00:00", "HH:mm:ss"),
                  }}
                />
                <ErrorMessage
                  name="validBefore"
                  component="p"
                  className="error"
                />
              </div>
              <div className="inputGroup">
                <Text strong>Maximum number of uses:</Text>
                <Input type="number" name="maxRedeems" />
                <ErrorMessage
                  name="maxRedeems"
                  component="p"
                  className="error"
                />
              </div>
              <div className="inputGroup">
                <Text strong>Amount:</Text>
                <Input type="number" name="bonusAmount" min={0} max={200} />
                <ErrorMessage
                  name="bonusAmount"
                  component="p"
                  className="error"
                />
              </div>
              <Button
                className="saveBtn"
                onClick={submitForm}
                disabled={isSubmitting}
                loading={isSubmitting}
                type="primary"
              >
                Create
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default CreateVoucherModal;
