import React from "react";
import { Button, Modal, Space, Typography } from "antd";
import * as Yup from "yup";

import "./index.scss";
import { ErrorMessage, Formik } from "formik";
import { Input, Select } from "formik-antd";
import { ICompanyDepartment } from "../../utils/interfaces";

const { Text } = Typography;

type PropTypes = {
  show: boolean;
  selectedDepartmentId?: number;
  titleEn?: string;
  titleDe?: string;
  titleFr?: string;
  titleIt?: string;
  costCenter?: string;
  parentDepartmentId?: number;
  companyDepartments?: ICompanyDepartment[];
  handleClose: () => void;
  confirm: (
    titleEn: string,
    titleDe: string,
    titleFr: string,
    titleIt: string,
    costCenter?: string,
    parentDepartmentId?: number,
    selectedDepartmentId?: number,
  ) => Promise<void>;
};

const validationSchema = Yup.object().shape({
  titleEn: Yup.string().trim().required("Required"),
  titleDe: Yup.string().trim().required("Required"),
  titleFr: Yup.string().trim().required("Required"),
  titleIt: Yup.string().trim().required("Required"),
});

const DepartmentModal: React.FC<PropTypes> = ({
  show,
  handleClose,
  confirm,
  titleEn = "",
  titleDe = "",
  titleFr = "",
  titleIt = "",
  costCenter = "",
  parentDepartmentId,
  selectedDepartmentId,
  companyDepartments
}) => {
  return (
    <Modal
      title="Add department:"
      visible={show}
      onCancel={() => {
        handleClose();
      }}
      footer={null}
    >
      <div className="wrapper">
        <Formik
          validationSchema={validationSchema}
          initialValues={{
            parentDepartmentId,
            titleEn,
            titleDe,
            titleFr,
            titleIt,
            costCenter
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            const { parentDepartmentId, titleEn, titleDe, titleFr, titleIt, costCenter } =
              values;
            await confirm(
              titleEn,
              titleDe,
              titleFr,
              titleIt,
              costCenter.length ? costCenter : undefined,
              parentDepartmentId,
              selectedDepartmentId
            );
            setSubmitting(true);
          }}
        >
          {({ isSubmitting, submitForm }) => (
            <Space direction="vertical" style={{ width: "100%" }}>
              <div className="inpLine">
                <Text strong>Title EN:</Text>
                <Input name="titleEn" />
                <ErrorMessage name="titleEn" component="p" className="error" />
              </div>
              <div className="inpLine">
                <Text strong>Title DE:</Text>
                <Input name="titleDe" />
                <ErrorMessage name="titleDe" component="p" className="error" />
              </div>
              <div className="inpLine">
                <Text strong>Title FR:</Text>
                <Input name="titleFr" />
                <ErrorMessage name="titleFr" component="p" className="error" />
              </div>
              <div className="inpLine">
                <Text strong>Title IT:</Text>
                <Input name="titleIt" />
                <ErrorMessage name="titleIt" component="p" className="error" />
              </div>
              <div className="inpLine">
                <Text strong>Cost center:</Text>
                <Input name="costCenter" />
                <ErrorMessage name="costCenter" component="p" className="error" />
              </div>
              <div className="inpLine">
                <Text strong>Parent department:</Text>
                <Select
                  name="parentDepartmentId"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    !!(option?.label as string)
                      ?.toLowerCase()
                      .startsWith(input.toLowerCase())
                  }>
                  {companyDepartments?.map((department) => (
                    <Select.Option value={department.id} label={department.title_en}>
                      {department.title_en}
                    </Select.Option>
                  ))}
                </Select>
                <ErrorMessage name="parentDepartmentId" component="p" className="error" />
              </div>
              <Button
                type="primary"
                loading={isSubmitting}
                onClick={submitForm}
              >
                {selectedDepartmentId ? "Update" : "Add"}
              </Button>
            </Space>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default DepartmentModal;
