import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  DeleteOutlined,
  LinkOutlined,
  QuestionCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Collapse,
  DatePicker,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Tooltip,
  Input as AntdInput,
  Popconfirm,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import { Typography } from "antd";
import * as Yup from "yup";

import InterpreterService from "../../utils/InterpreterService";
import LanguagesService from "../../utils/LanguagesService";
import FinanceService from "../../utils/FinanceService";
import {
  IInterpreterUser,
  ILanguage,
  IStripeStatus,
  VacationTime,
} from "../../utils/interfaces";
import {
  certificateUrl,
  imageUrl,
  isValidHttpsUrl,
  statusProofUrl,
  validURL,
} from "../../helpers/urlHelpers";
import { stripeCountries } from "../../utils/stripeCountries";
import { languageSelector } from "../../helpers/languageSelector";

import "./style.scss";
import { ErrorMessage, Formik } from "formik";
import { Form, Input } from "formik-antd";
import { transformPrice } from "../../helpers/transform";
import AdminService from "../../utils/AdminService";
const frontUrl = process.env.REACT_APP_FRONT_URL || "https://app.dolmx.ch";

const { Option } = Select;
const { RangePicker } = DatePicker;

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .trim()
    .min(8, "Minimum 8 symbols!")
    .max(20, "Maximum 20 symbols!")
    .matches(
      /^(?=.*[a-zA-Z])(?=.*[0-9]).+$/,
      "Should have at least one number and letter"
    )
    .required("Password is required"),
});

const { Title, Text } = Typography;
const { Panel } = Collapse;

const getStripeWebsite = (website: string) => {
  if (website.startsWith("http")) {
    return website;
  }
  return `${frontUrl}/interpreter/${website}`;
};

const Interpreter: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [interpreter, setInterpreter] = useState<IInterpreterUser>();
  const [spokenLanguages, setSpokenLanguages] = useState<ILanguage[]>([]);
  const [signLanguages, setSignLanguages] = useState<ILanguage[]>([]);
  const [vacationTimes, setVacationTimes] = useState<VacationTime[]>([]);
  const [stripeStatus, setStripeStatus] = useState<IStripeStatus>();
  const [interpreterEarnings, setInterpreterEarnings] = useState<string>("0");
  const [earnignsYear, setEarnignsYear] = useState<string>("");
  const [loadingStatusBtn, setLoadingStatusBtn] = useState(false);
  const [loadingInvoicesBtn, setLoadingInvoicesBtn] = useState(false);
  const [changeWebsiteModal, setChangeWebsiteModal] = useState(false);
  const [websiteValue, setWebsiteValue] = useState("https://");
  const [superLoginStep, setSuperLoginStep] = useState(0);
  const [interpreterJWT, setInterpreterJWT] = useState("");
  const [invoiceRange, setInvoiceRange] = useState<any>(null);
  const [deleteCode, setDeleteCode] = useState("");
  const [verificationCode, setVerificationCode] = useState("");

  useEffect(() => {
    if (!id) return;

    setEarnignsYear(new Date().getUTCFullYear().toString());
    const init = async () => {
      const fetchedInterpreter = await InterpreterService.getInterpreter(id);
      const spoken = await LanguagesService.getSpokenLanguages();
      const sign = await LanguagesService.getSignLanguages();
      const fetchedVacations = await InterpreterService.getVacations(
        fetchedInterpreter.profileId
      );

      if (fetchedInterpreter.stripeAccount) {
        const fetchedStripeStatus =
          await InterpreterService.getInterpreterStripeStatus(id);
        setStripeStatus(fetchedStripeStatus);
      }

      setInterpreter(fetchedInterpreter);
      setSpokenLanguages(spoken);
      setSignLanguages(sign);
      setVacationTimes(fetchedVacations);
      const websiteToSet =
        fetchedInterpreter?.website &&
          fetchedInterpreter.website.startsWith("https://")
          ? fetchedInterpreter.website
          : "https://";
      setWebsiteValue(websiteToSet);
    };

    init();
  }, [id]);

  useEffect(() => {
    if (!earnignsYear || !id) return;

    const fetchEarnings = async () => {
      const fetchedEadnings = await FinanceService.getInterpreterYearEarnings(
        id,
        earnignsYear
      );

      setInterpreterEarnings(fetchedEadnings || "0");
    };

    fetchEarnings();
  }, [earnignsYear, id]);

  const handleUpdateStatus = async (status: boolean) => {
    if (!id || !interpreter) return;

    setLoadingStatusBtn(true);

    const fetchedInterpreter = await InterpreterService.updateInterpreterStatus(
      id,
      status
    );

    setInterpreter({
      ...interpreter,
      isActive: fetchedInterpreter.isActive,
      isReviewed: fetchedInterpreter.isReviewed,
    });

    setLoadingStatusBtn(false);
  };

  const handleDownloadInvoices = async () => {
    if (!interpreter) return;
    const from = invoiceRange?.[0]?.startOf("day").toISOString();
    const to = invoiceRange?.[1]?.endOf("day").toISOString();
    setLoadingInvoicesBtn(true);
    try {
      await FinanceService.downloadInvoicesRange({ from, to, interpreter });
    } catch (err: any) {
      message.error(err?.response?.statusText || "Something went wrong!");
    }
    setLoadingInvoicesBtn(false);
  };

  const isOnboarded = () => {
    return interpreter &&
      interpreter.address &&
      interpreter.languages &&
      interpreter.workingLanguages.length &&
      interpreter.certificates.length &&
      (interpreter.statusProof || interpreter.employmentStatus) &&
      interpreter.expertiseAreas.length &&
      interpreter.certificates &&
      interpreter.about
      ? true
      : false;
  };

  const getStatusIcon = (field: any) => {
    return field ? <CheckOutlined /> : <CloseOutlined />;
  };

  const handleClose = () => {
    setSuperLoginStep(0);
  };

  const generateSelectOptions = () => {
    let currentDate = new Date();
    const options = [];

    do {
      const year = currentDate.getUTCFullYear();

      options.push(<Option value={year}>{year}</Option>);

      currentDate.setUTCFullYear(year - 1);
    } while (currentDate.getUTCFullYear() >= 2021);

    return options;
  };

  const handleWebsiteChange = (value: string) => {
    if (value.length > 250) return;
    if (value === "https://" || isValidHttpsUrl(value)) {
      setWebsiteValue(value);
      return;
    }
    if (value.length < "https://".length) {
      setWebsiteValue("https://");
      return;
    }
    const url = `https://${value}`;
    if (isValidHttpsUrl(url)) setWebsiteValue(url);
  };

  const handleWebsiteUpdate = async (generateNew = false) => {
    if (!interpreter) return;
    await InterpreterService.changeInterpreterWebsite(
      interpreter.profileId,
      generateNew ? undefined : websiteValue
    );
    setInterpreter({ ...interpreter, website: websiteValue });
    setChangeWebsiteModal(false);
  };

  const handleInterpreterDelete = async () => {
    const codeSecret = await AdminService.verifyMe();
    setDeleteCode(codeSecret);
  };

  const confirmCompanyDelete = async () => {
    if (!interpreter || !deleteCode.length || !verificationCode.length) {
      message.error("Something went wrong");
      return;
    }
    try {
      await InterpreterService.deleteInterpreter(
        interpreter.profileId,
        verificationCode,
        deleteCode
      );
      message.success(`Interpreter was removed successfully`);
      navigate("/admin/companies");
    } catch (err) {
      message.error("Something went wrong");
    }
  };

  return (
    <div>
      {interpreter && (
        <>
          <Title>
            {`${interpreter.firstName} ${interpreter.lastName}`}{" "}
            <Space>
              <Link to={`/admin/interpreters/${id}/edit`}>
                <EditOutlined style={{ fontSize: 26 }} className="editBtn" />
              </Link>
              <Popconfirm
                title="Delete interpreter?"
                onConfirm={handleInterpreterDelete}
                okText="Yes"
                cancelText="No"
              >
                <DeleteOutlined style={{ fontSize: 26 }} />
              </Popconfirm>
            </Space>
          </Title>
          <Title level={4}>
            Application completed:
            {getStatusIcon(isOnboarded())}
          </Title>
          <div className="statusBtn">
            {interpreter.isActive ? (
              <Button
                loading={loadingStatusBtn}
                danger
                type="primary"
                onClick={() => handleUpdateStatus(false)}
              >
                Deactivate
              </Button>
            ) : (
              <Button
                loading={loadingStatusBtn}
                type="primary"
                onClick={() => handleUpdateStatus(true)}
              >
                Activate
              </Button>
            )}
            {!interpreter.isReviewed && (
              <Button
                loading={loadingStatusBtn}
                danger
                type="primary"
                onClick={() => handleUpdateStatus(false)}
                style={{ marginLeft: 15 }}
              >
                Reject
              </Button>
            )}
          </div>

          <Row>
            <Col span={6}>
              <Avatar
                size={216}
                icon={<UserOutlined />}
                src={imageUrl(interpreter.picture?.name)}
              />
              <Button
                type="primary"
                onClick={() => setSuperLoginStep(1)}
                style={{ marginTop: 15 }}
              >
                Login as: {`${interpreter.firstName} ${interpreter.lastName}`}
              </Button>
            </Col>
            <Col
              span={18}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Row>
                <Col span={12}>
                  <Space direction="vertical" style={{ alignItems: "start" }}>
                    <Text strong>Date of birth: {interpreter.bornAt}</Text>
                    <Text strong>
                      Registered:{" "}
                      {new Date(interpreter.createdAt).toLocaleString()}
                    </Text>
                    <Text strong>Gender: {interpreter.gender}</Text>
                    <Text strong>
                      Rating:{" "}
                      {interpreter.rating?.overall?.average.toFixed(2) ?? "–"}
                    </Text>
                    <Text strong>Price: {interpreter.price ?? "–"}</Text>
                    <Text strong>
                      Charge VAT: {interpreter.chargeVat?.toString()}
                    </Text>
                    <Text strong>VAT ID: {interpreter.vatId || "-"}</Text>
                    <Text strong>
                      Comments: {interpreter.comments}{" "}
                    </Text>
                  </Space>
                </Col>
                <Col span={12}>
                  <Space direction="vertical" style={{ alignItems: "start" }}>
                    <Text strong>
                      Country:{" "}
                      {interpreter.address.country &&
                        (stripeCountries as any)[
                        interpreter.address.country.toUpperCase()
                        ]}
                    </Text>
                    <Text strong>City: {interpreter.address.city}</Text>
                    <Text strong>Streeet: {interpreter.address.street}</Text>
                    <Text strong>
                      Postal Code: {interpreter.address.postalCode}
                    </Text>
                    <Text strong>Email: {interpreter.email}</Text>
                    <Text strong>Phone: {interpreter.phone}</Text>
                    <Text strong>
                      Earnings: {transformPrice(Number(interpreterEarnings))}{" "}
                      CHF{" "}
                      <Select
                        className="yearSelect"
                        value={earnignsYear}
                        onChange={setEarnignsYear}
                        style={{ width: 240 }}
                      >
                        {generateSelectOptions()}
                      </Select>
                    </Text>
                  </Space>
                </Col>
                <Col span={24} style={{ marginTop: 20 }}>
                  <Text strong>
                    Get Invoices: <RangePicker onChange={setInvoiceRange} />
                  </Text>
                  <Button
                    loading={loadingInvoicesBtn}
                    type="primary"
                    onClick={handleDownloadInvoices}
                    style={{ marginLeft: 15 }}
                    disabled={!invoiceRange}
                  >
                    Download
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col style={{ marginTop: 30 }} span={20} offset={2}>
                  <Space direction="vertical" style={{ alignItems: "start" }}>
                    <Text strong>Description: {interpreter.about ?? "-"}</Text>
                  </Space>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row style={{ marginTop: 30 }}>
            <Col span={12} className="columnView">
              <Space direction="vertical" style={{ alignItems: "start" }}>
                <Title level={3}>Native Languages:</Title>
                {!!interpreter.nativeLanguages.length ? (
                  interpreter.nativeLanguages.map((language, i) => (
                    <Text key={language.id} strong>
                      {`${i + 1}. ${language.title_en}`}{" "}
                    </Text>
                  ))
                ) : (
                  <Text strong>-</Text>
                )}
              </Space>
            </Col>
            <Col span={12} className="columnView">
              <Space direction="vertical" style={{ alignItems: "start" }}>
                <Title level={3}>Working Languages:</Title>
                {!!interpreter.workingLanguages.length ? (
                  interpreter.workingLanguages.map((lang, i) => (
                    <Fragment key={lang.code}>
                      <Text strong>
                        {`${i + 1}. ${languageSelector(
                          lang.code,
                          spokenLanguages,
                          signLanguages
                        )}`}
                      </Text>
                      <Text style={{ marginLeft: 15, fontSize: 12 }}>
                        Language type:
                      </Text>
                      <Radio.Group
                        style={{ marginLeft: 15 }}
                        value={lang.code.length}
                      >
                        <Radio disabled={lang.code.length !== 2} value={2}>
                          Spoken language
                        </Radio>
                        <Radio disabled={lang.code.length !== 3} value={3}>
                          Sign language
                        </Radio>
                      </Radio.Group>
                      <Text style={{ marginLeft: 15, fontSize: 12 }}>
                        Proficiency:
                      </Text>
                      <Radio.Group
                        style={{ marginLeft: 15 }}
                        value={lang.proficiency}
                      >
                        <Radio disabled={lang.proficiency !== "a1"} value="a1">
                          A1
                        </Radio>
                        <Radio disabled={lang.proficiency !== "a2"} value="a2">
                          A2
                        </Radio>
                        <Radio disabled={lang.proficiency !== "b1"} value="b1">
                          B1
                        </Radio>
                        <Radio disabled={lang.proficiency !== "b2"} value="b2">
                          B2
                        </Radio>
                        <Radio disabled={lang.proficiency !== "c1"} value="c1">
                          C1
                        </Radio>
                        <Radio disabled={lang.proficiency !== "c2"} value="c2">
                          C2
                        </Radio>
                      </Radio.Group>
                    </Fragment>
                  ))
                ) : (
                  <Text style={{ marginLeft: 15 }} strong>
                    -
                  </Text>
                )}
              </Space>
            </Col>
          </Row>

          <Row style={{ marginTop: 30 }}>
            <Col span={12} className="columnView">
              <Space
                direction="vertical"
                style={{ alignItems: "start", textAlign: "start" }}
              >
                <Title level={3}>Qualifications & work experience:</Title>
                <Text style={{ marginLeft: 15, fontSize: 12 }}>
                  Academic Degree:
                </Text>
                {interpreter.academicDegree !== null ? (
                  <Radio.Group
                    style={{ marginLeft: 15 }}
                    value={interpreter.academicDegree}
                  >
                    <Radio disabled={!interpreter.academicDegree} value={true}>
                      Yes
                    </Radio>
                    <Radio disabled={interpreter.academicDegree} value={false}>
                      No
                    </Radio>
                  </Radio.Group>
                ) : (
                  <Text style={{ marginLeft: 15, fontSize: 12 }}>-</Text>
                )}

                <Text style={{ marginLeft: 15, fontSize: 12 }}>
                  Field(s) of study:
                </Text>
                <Text style={{ marginLeft: 15 }} strong>
                  {interpreter.studyFields || "-"}
                </Text>
                <Text style={{ marginLeft: 15, fontSize: 12 }}>
                  Academic titles:
                </Text>
                <Text style={{ marginLeft: 15 }} strong>
                  {interpreter.academicTitles || "-"}
                </Text>
                <Text style={{ marginLeft: 15, fontSize: 12 }}>
                  Areas of expertise:
                </Text>
                <Text strong style={{ marginLeft: 15, textAlign: "start" }}>
                  {!!interpreter.expertiseAreas.length ? (
                    interpreter.expertiseAreas.map((ex, i) =>
                      i > 0 ? `, ${ex.title_en}` : ex.title_en
                    )
                  ) : (
                    <Text style={{ marginLeft: 15, fontSize: 12 }}>-</Text>
                  )}
                </Text>
                <Text style={{ marginLeft: 15, fontSize: 12 }}>
                  Self Employed:
                </Text>
                {interpreter.selfEmployed !== null ? (
                  <Radio.Group
                    style={{ marginLeft: 15 }}
                    value={interpreter.selfEmployed}
                  >
                    <Radio disabled={!interpreter.selfEmployed} value={true}>
                      Yes
                    </Radio>
                    <Radio disabled={interpreter.selfEmployed} value={false}>
                      No
                    </Radio>
                  </Radio.Group>
                ) : (
                  <Text style={{ marginLeft: 15, fontSize: 12 }}>-</Text>
                )}
                <Text style={{ marginLeft: 15, fontSize: 12 }}>
                  Employment Status:
                </Text>
                <Text style={{ marginLeft: 15 }} strong>
                  {interpreter.employmentStatus || "-"}
                </Text>
              </Space>
            </Col>
            <Col span={12} className="columnView">
              <Space
                direction="vertical"
                style={{ alignItems: "start", textAlign: "start" }}
              >
                <Title level={3}>Certifications, diplomas & references:</Title>
                {interpreter.certificates.map((certificate, i) => (
                  <a
                    href={certificateUrl(certificate.name)}
                    target="_blank"
                    rel="noreferrer"
                    key={certificate.id}
                  >
                    <div className="certificate">
                      <Text className="number" strong>
                        {i + 1}.
                      </Text>
                      <div>
                        <div>{certificate.name}</div>
                        <Text type="secondary">type: {certificate.type}</Text>
                      </div>
                    </div>
                  </a>
                ))}
              </Space>
            </Col>
          </Row>

          <Row style={{ marginTop: 30, marginBottom: 100 }}>
            <Col span={12} className="columnView">
              <Space direction="vertical" style={{ alignItems: "start" }}>
                <Title level={3}>Stripe Details:</Title>
                <Text
                  strong
                  style={{
                    textAlign: "left",
                    maxWidth: "30rem",
                    display: "block",
                  }}
                >
                  1. Website
                  <Tooltip title="Website used for stripe profile">
                    <QuestionCircleOutlined
                      style={{ marginLeft: 5, marginRight: 5 }}
                    />
                  </Tooltip>
                  :{" "}
                  {interpreter.website ? (
                    <a
                      href={getStripeWebsite(interpreter.website)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {getStripeWebsite(interpreter.website)}
                    </a>
                  ) : (
                    <CloseOutlined />
                  )}{" "}
                  <EditOutlined onClick={() => setChangeWebsiteModal(true)} />
                </Text>
                <Text strong>
                  2. Details submitted
                  <Tooltip title="Interpreter has submitted Stripe documents">
                    <QuestionCircleOutlined
                      style={{ marginLeft: 5, marginRight: 5 }}
                    />
                  </Tooltip>
                  : {getStatusIcon(stripeStatus?.detailsSubmitted)}
                </Text>
                <Text strong>
                  3. Pending Verification
                  <Tooltip title="Stripe is waiting documents for verification">
                    <QuestionCircleOutlined
                      style={{ marginLeft: 5, marginRight: 5 }}
                    />
                  </Tooltip>
                  : {getStatusIcon(stripeStatus?.pendingVerification)}
                </Text>
                <Text strong>
                  4. Payouts Enabled :{" "}
                  {getStatusIcon(stripeStatus?.payoutsEnabled)}
                </Text>

                {!!stripeStatus?.requirementsErrors?.length && (
                  <>
                    <Text strong>5. Requirements Errors: </Text>
                    <Collapse accordion>
                      {stripeStatus.requirementsErrors.map((error, i) => (
                        <Panel header={error.requirement} key={i}>
                          <p>{error.reason}</p>
                        </Panel>
                      ))}
                    </Collapse>
                  </>
                )}
              </Space>
            </Col>
            <Col span={12} className="columnView">
              <Space
                direction="vertical"
                style={{ alignItems: "start", textAlign: "start" }}
              >
                <Title level={3}>Vacation times:</Title>
                {!vacationTimes.length ? (
                  <p>no vacation</p>
                ) : (
                  <>
                    {vacationTimes.map((v, i) => (
                      <Text>
                        <b>{++i}.</b> {v.startDate} - {v.endDate}
                      </Text>
                    ))}
                  </>
                )}
              </Space>
            </Col>

            {interpreter.selfEmployed && (
              <Col span={12} className="columnView">
                <Space
                  direction="vertical"
                  style={{ alignItems: "start", textAlign: "start" }}
                >
                  <Title level={3}>Status Proof:</Title>
                  {
                    <a
                      href={statusProofUrl(interpreter.statusProof?.name)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="certificate">
                        <div>
                          <div>{interpreter.statusProof?.name}</div>
                        </div>
                      </div>
                    </a>
                  }
                </Space>
              </Col>
            )}
          </Row>
        </>
      )}
      <Modal
        title={`Login as: ${interpreter?.firstName} ${interpreter?.lastName}`}
        visible={!!superLoginStep}
        onCancel={handleClose}
        footer={null}
      >
        {superLoginStep === 1 && (
          <Formik
            initialValues={{ password: "" }}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting, setFieldError }) => {
              setSubmitting(true);

              try {
                const code = await InterpreterService.loginAsInterpreter(
                  interpreter?.email as string,
                  values.password
                );
                setInterpreterJWT(code);
                setSuperLoginStep(2);
              } catch (err: any) {
                const { message } = err.response.data;
                setFieldError("password", message);
              } finally {
                setSubmitting(false);
              }
            }}
          >
            {({ isSubmitting, submitForm }) => (
              <Form>
                <div className="loginGroup">
                  <div className="inputGroup">
                    <Text strong>Confirm password:</Text>
                    <Input
                      style={{ maxWidth: 250 }}
                      type="password"
                      name="password"
                    />
                    <ErrorMessage
                      name="password"
                      component="p"
                      className="error"
                    />
                  </div>
                  <Button
                    type="primary"
                    loading={isSubmitting}
                    onClick={() => submitForm()}
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        )}
        {superLoginStep === 2 && (
          <div>
            <p>
              At the dolmx.ch add to the localStorage{" "}
              <Text strong>@access_token</Text> key with this code as value:
            </p>
            <div className="linkGroup">
              <LinkOutlined />
              <p>{interpreterJWT}</p>
              <Tooltip title="Copied!" trigger="click">
                <Button
                  type="primary"
                  onClick={() => navigator.clipboard.writeText(interpreterJWT)}
                >
                  Copy
                </Button>
              </Tooltip>
              ,
            </div>

            <div className="footerBtnGroup">
              <Button
                onClick={() => {
                  handleClose();
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        )}
      </Modal>
      <Modal
        title="Change website link"
        visible={changeWebsiteModal}
        onCancel={() => setChangeWebsiteModal(false)}
        footer={null}
      >
        <Space direction="vertical">
          <Button type="primary" onClick={() => handleWebsiteUpdate(true)}>
            Generate on dolmX
          </Button>
          <AntdInput
            placeholder="Enter website name"
            value={websiteValue}
            onChange={({ target: { value } }) => handleWebsiteChange(value)}
          />
          <Button
            disabled={
              websiteValue === interpreter?.website || !validURL(websiteValue)
            }
            type="primary"
            onClick={() => handleWebsiteUpdate()}
          >
            Update
          </Button>
        </Space>
      </Modal>

      <Modal
        title="Enter code"
        visible={!!deleteCode.length}
        onOk={confirmCompanyDelete}
        onCancel={handleClose}
      >
        <AntdInput
          value={verificationCode}
          onChange={(v) => setVerificationCode(v.target.value)}
        />
      </Modal>
    </div>
  );
};

export default Interpreter;
