import ApiService from "./ApiService";
import { ILanguage } from "./interfaces";

class LanguagesService extends ApiService {
  public async getSpokenLanguages(): Promise<ILanguage[]> {
    const response = await this.request({
      url: "languages",
    });

    return response.data;
  }

  public async getSignLanguages(): Promise<ILanguage[]> {
    const response = await this.request({
      url: "languages/sign",
    });

    return response.data;
  }
}

const instance = new LanguagesService();

export default instance;
