import axios, { AxiosRequestConfig, AxiosResponse, AxiosInstance } from "axios";
import jwtDecode from "jwt-decode";
import tokenStorage from "./TokenStorage";

const baseUrl = process.env.REACT_APP_API_URL;

const config = {
  apiUrl: baseUrl ?? "https://api-stage.dolmx.ch/api",
};

abstract class ApiService {
  private tokenStorage = tokenStorage;

  private readonly api: AxiosInstance = axios.create({
    baseURL: config.apiUrl,
  });

  protected getConfigs(): {
    url: string;
    token: string | null;
  } {
    const token = this.tokenStorage.getAccessToken();
    return {
      url: config.apiUrl,
      token,
    };
  }

  protected async request<ResponseType = any>(
    inputConfig: AxiosRequestConfig
  ): Promise<AxiosResponse<ResponseType>> {
    let outputConfig = inputConfig;
    const token = this.tokenStorage.getAccessToken();
    if (token) {
      outputConfig = {
        ...outputConfig,
        headers: { Authorization: `Bearer ${token}` },
      };
    }
    try {
      const res = await this.api(outputConfig);
      return res;
    } catch (err: any) {
      if (
        token &&
        err.response?.status === 401 &&
        typeof window !== "undefined"
      ) {
        return this.refreshToken(outputConfig);
      }
      throw err;
    }
  }

  protected async refreshToken<ResponseType = any>(
    inputConfig: AxiosRequestConfig
  ): Promise<AxiosResponse<ResponseType>> {
    const tokens = this.tokenStorage.get();
    if (tokens) {
      this.tokenStorage.remove();
      try {
        const { accessToken, refreshToken } = tokens;
        const { email }: { email: string } = jwtDecode(accessToken);
        const updatedTokens = await this.api.post<{
          accessToken: string;
          refreshToken: string;
          notificationSecret: string;
        }>("/auth/refresh", {
          refreshToken,
          email,
        });
        const {
          accessToken: fetchedAccessToken,
          refreshToken: fetchedRefreshToken,
          notificationSecret: fetchedNotificationSecret,
        } = updatedTokens.data;
        this.setToken(
          fetchedAccessToken,
          fetchedRefreshToken,
          fetchedNotificationSecret
        );
        const outputConfig = {
          ...inputConfig,
          headers: { Authorization: `Bearer ${fetchedAccessToken}` },
        };
        const res = await this.api(outputConfig);
        return res;
      } catch (err: any) {
        if (err.response?.status === 401 && typeof window !== "undefined") {
          this.tokenStorage.remove();
          window.location.reload();
        }
        throw err;
      }
    } else {
      try {
        const res = await this.api(inputConfig);
        return res;
      } catch (err: any) {
        if (err.response?.status === 401 && typeof window !== "undefined") {
          this.tokenStorage.remove();
          window.location.reload();
        }
        throw err;
      }
    }
  }

  protected setToken(
    accessToken: string,
    refreshToken: string,
    notificationSecret: string
  ): void {
    return this.tokenStorage.set(accessToken, refreshToken, notificationSecret);
  }

  protected deleteTokens(): void {
    return this.tokenStorage.remove();
  }
}

export default ApiService;
