import tokenStorage from "../utils/TokenStorage";

const baseUrl =
  process.env.REACT_APP_API_URL || "https://api-stage.dolmx.ch/api/";

const appUrl = process.env.REACT_APP_DOLMX_URL || "https://stage.dolmx.ch/";

export const certificateUrl = (name: string): string =>
  `${baseUrl}files/certificates/admin/${name}?token=${tokenStorage.getAccessToken()}`;
export const imageUrl = (name: string | undefined): string =>
  `${baseUrl}files/images/${name}`;
export const statusProofUrl = (name: string | undefined): string =>
  `${baseUrl}files/status-proofs/admin/${name}?token=${tokenStorage.getAccessToken()}`;
export const registerEmployeeUrl = (code: string, email: string): string =>
  `${appUrl}register/employee?code=${code}&email=${email}`;

export const isValidHttpsUrl = (string: string): boolean => {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "https:";
};

export const validURL = (str: string): boolean => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
};
