import React, { Key, useEffect, useRef, useState } from "react";
import {
  Avatar,
  Button,
  DatePicker,
  Input,
  message,
  Space,
  Table,
  TablePaginationConfig,
  Typography,
} from "antd";
import { SearchOutlined, UserOutlined } from "@ant-design/icons";

import CompanyService from "../../utils/CompanyService";
import { IFilterItems } from "../../utils/interfaces";
import { imageUrl } from "../../helpers/urlHelpers";
import { Link } from "react-router-dom";
import { FilterDropdownProps } from "antd/lib/table/interface";
import Highlighter from "react-highlight-words";
import FinanceService from "../../utils/FinanceService";
import { transformPrice } from "../../helpers/transform";
import VideosessionService from "../../utils/VideosessionService";

const { Title, Text } = Typography;
const { RangePicker } = DatePicker;

interface ICompanyItem {
  key: number;
  name: string;
  logo: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  outstandingBalance: number;
}

const Dashboard: React.FC = () => {
  const [dataSource, setDataSource] = useState<ICompanyItem[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState<IFilterItems>({
    current: 1,
    pageSize: 10,
    email: "",
    companyName: "",
    balance: "OUTSTANDING",
  });
  const [state, setState] = useState({ searchText: "", searchedColumn: "" });
  const [invoiceRange, setInvoiceRange] = useState<any>(null);
  const [loadingInvoicesBtn, setLoadingInvoicesBtn] = useState(false);
  const [logsRange, setLogsRange] = useState<any>(null);
  const [loadingLogsBtn, setLoadingLogsBtn] = useState(false);
  const emailSearchInput = useRef<Input>(null);
  const companyNameSearchInput = useRef<Input>(null);

  useEffect(() => {
    const getInterpreters = async () => {
      if (!filter.current || !filter.pageSize) return;
      setLoading(true);

      const { companies, count } = await CompanyService.getCompanies(filter);

      setTotalCount(count);
      const dataToSet: ICompanyItem[] = companies.map((company) => {
        const companyItem: ICompanyItem = {
          key: company.id,
          name: company.name,
          logo: company?.logo?.name,
          firstName: company.user.firstName,
          lastName: company.user.lastName,
          phone: company.user.phone,
          email: company.user.email,
          outstandingBalance: company.outstandingBalance,
        };
        return companyItem;
      });
      setDataSource(dataToSet);

      setLoading(false);
    };
    getInterpreters();
  }, [filter]);

  const handleTableChange = ({
    pagination,
  }: {
    pagination: TablePaginationConfig;
  }) => {
    const { current, pageSize } = pagination;

    setFilter({
      ...filter,
      current: current as number,
      pageSize: pageSize as number,
      [state.searchedColumn]: state.searchText,
    });
  };

  const handleSearch = (
    selectedKeys: Key[],
    confirm: () => void,
    dataIndex: string
  ) => {
    confirm();
    setState({
      searchText: selectedKeys[0] as string,
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters: (() => void) | undefined) => {
    if (!clearFilters) return;
    clearFilters();
    setState({ searchText: "", searchedColumn: "" });
  };

  const getRefToSet = (dataIndex: string) => {
    switch (dataIndex) {
      case "companyName":
        return companyNameSearchInput;
      case "email":
        return emailSearchInput;
    }
  };

  const handleDownloadInvoices = async () => {
    const from = invoiceRange?.[0]?.startOf("day").toISOString();
    const to = invoiceRange?.[1]?.endOf("day").toISOString();
    const fromDate = invoiceRange?.[0]?.format("YYYY-MM-DD");
    const toDate = invoiceRange?.[1]?.format("YYYY-MM-DD");
    setLoadingInvoicesBtn(true);
    try {
      await FinanceService.downloadInvoicesRange({
        from,
        to,
        fromDate,
        toDate,
      });
    } catch (err: any) {
      message.error(err?.response?.statusText || "Something went wrong!");
    }
    setLoadingInvoicesBtn(false);
  };

  const handleDownloadLogs = async () => {
    const from = logsRange?.[0]?.startOf("day").toISOString();
    const to = logsRange?.[1]?.endOf("day").toISOString();
    const fromDate = logsRange?.[0]?.format("YYYY-MM-DD");
    const toDate = logsRange?.[1]?.format("YYYY-MM-DD");
    setLoadingLogsBtn(true);
    try {
      await VideosessionService.downloadLogsRange({
        from,
        to,
        fromDate,
        toDate,
      });
    } catch (err: any) {
      message.error(err?.response?.statusText || "Something went wrong!");
    }
    setLoadingLogsBtn(false);
  };

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: FilterDropdownProps) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={getRefToSet(dataIndex)}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            setState({
              searchText: e.target.value,
              searchedColumn: dataIndex,
            });
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            onClick={() => {
              setState({ searchText: "", searchedColumn: "" });

              handleReset(clearFilters);
              setTimeout(() => getRefToSet(dataIndex)?.current?.focus(), 100);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => getRefToSet(dataIndex)?.current?.focus(), 100);
      }
    },
    render: (text: string) =>
      state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Logo",
      dataIndex: "logo",
      key: "logo",
      render: (name: string) => (
        <Avatar size={42} icon={<UserOutlined />} src={imageUrl(name)} />
      ),
    },
    {
      title: "Company Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("companyName"),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ...getColumnSearchProps("email"),
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Outstanding Balance",
      dataIndex: "outstandingBalance",
      key: "outstandingBalance",
      render: (outstandingBalance: number) => (
        <Text strong type="danger">
          {transformPrice(outstandingBalance)} CHF
        </Text>
      ),
    },
    {
      title: "Details",
      dataIndex: "key",
      key: "key",
      render: (id: string) => (
        <Link to={`/admin/companies/${id}`}>Details</Link>
      ),
    },
  ];

  return (
    <div className="App">
      <Title level={3}>Companies with outstanding balance</Title>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={{
          ...filter,
          total: totalCount,
          pageSizeOptions: ["10", "25", "50"],
          showSizeChanger: true,
        }}
        loading={loading}
        onChange={(pagination) => handleTableChange({ pagination })}
      />

      <Title level={3}> Get All Interpreters Invoices:</Title>
      <Text strong>
        <RangePicker onChange={setInvoiceRange} />
      </Text>
      <Button
        loading={loadingInvoicesBtn}
        type="primary"
        onClick={handleDownloadInvoices}
        style={{ marginLeft: 15 }}
        disabled={!invoiceRange}
      >
        Download
      </Button>

      <Title level={3} style={{ marginTop: "3rem" }}>
        Get All Session Logs:
      </Title>
      <Text strong>
        <RangePicker onChange={setLogsRange} />
      </Text>
      <Button
        loading={loadingLogsBtn}
        type="primary"
        onClick={handleDownloadLogs}
        style={{ marginLeft: 15 }}
        disabled={!logsRange}
      >
        Download
      </Button>
    </div>
  );
};

export default Dashboard;
