const dialectCountries = [
  {
    title_en: "Afghanistan",
    title_de: "Afghanistan",
    title_fr: "Afghanistan",
    title_it: "Afghanistan",
    code: "af",
  },
  {
    title_en: "Albania",
    title_de: "Albanien",
    title_fr: "Albanie",
    title_it: "Albania",
    code: "al",
  },
  {
    title_en: "Algeria",
    title_de: "Algerien",
    title_fr: "Algérie",
    title_it: "Algeria",
    code: "dz",
  },
  {
    title_en: "Andorra",
    title_de: "Andorra",
    title_fr: "Andorre",
    title_it: "Andorra",
    code: "ad",
  },
  {
    title_en: "Angola",
    title_de: "Angola",
    title_fr: "Angola",
    title_it: "Angola",
    code: "ao",
  },
  {
    title_en: "Antigua and Barbuda",
    title_de: "Antigua und Barbuda",
    title_fr: "Antigua-et-Barbuda",
    title_it: "Antigua e Barbuda",
    code: "ag",
  },
  {
    title_en: "Argentina",
    title_de: "Argentinien",
    title_fr: "Argentine",
    title_it: "Argentina",
    code: "ar",
  },
  {
    title_en: "Armenia",
    title_de: "Armenien",
    title_fr: "Arménie",
    title_it: "Armenia",
    code: "am",
  },
  {
    title_en: "Australia",
    title_de: "Australien",
    title_fr: "Australie",
    title_it: "Australia",
    code: "au",
  },
  {
    title_en: "Austria",
    title_de: "Österreich",
    title_fr: "Autriche",
    title_it: "Austria",
    code: "at",
  },
  {
    title_en: "Azerbaijan",
    title_de: "Aserbaidschan",
    title_fr: "Azerbaïdjan",
    title_it: "Azerbaigian",
    code: "az",
  },
  {
    title_en: "Bahamas",
    title_de: "Bahamas",
    title_fr: "Bahamas",
    title_it: "Bahamas",
    code: "bs",
  },
  {
    title_en: "Bahrain",
    title_de: "Bahrain",
    title_fr: "Bahreïn",
    title_it: "Bahrein",
    code: "bh",
  },
  {
    title_en: "Bangladesh",
    title_de: "Bangladesch",
    title_fr: "Bangladesh",
    title_it: "Bangladesh",
    code: "bd",
  },
  {
    title_en: "Barbados",
    title_de: "Barbados",
    title_fr: "Barbade",
    title_it: "Barbados",
    code: "bb",
  },
  {
    title_en: "Belarus",
    title_de: "Belarus",
    title_fr: "Biélorussie",
    title_it: "Bielorussia",
    code: "by",
  },
  {
    title_en: "Belgium",
    title_de: "Belgien",
    title_fr: "Belgique",
    title_it: "Belgio",
    code: "be",
  },
  {
    title_en: "Belize",
    title_de: "Belize",
    title_fr: "Belize",
    title_it: "Belize",
    code: "bz",
  },
  {
    title_en: "Benin",
    title_de: "Benin",
    title_fr: "Bénin",
    title_it: "Benin",
    code: "bj",
  },
  {
    title_en: "Bhutan",
    title_de: "Bhutan",
    title_fr: "Bhoutan",
    title_it: "Bhutan",
    code: "bt",
  },
  {
    title_en: "Bolivia",
    title_de: "Bolivien",
    title_fr: "Bolivie",
    title_it: "Bolivia",
    code: "bo",
  },
  {
    title_en: "Bosnia and Herzegovina",
    title_de: "Bosnien und Herzegowina",
    title_fr: "Bosnie-Herzégovine",
    title_it: "Bosnia ed Erzegovina",
    code: "ba",
  },
  {
    title_en: "Botswana",
    title_de: "Botswana",
    title_fr: "Botswana",
    title_it: "Botswana",
    code: "bw",
  },
  {
    title_en: "Brazil",
    title_de: "Brasilien",
    title_fr: "Brésil",
    title_it: "Brasile",
    code: "br",
  },
  {
    title_en: "Brunei",
    title_de: "Brunei",
    title_fr: "Brunei",
    title_it: "Brunei",
    code: "bn",
  },
  {
    title_en: "Bulgaria",
    title_de: "Bulgarien",
    title_fr: "Bulgarie",
    title_it: "Bulgaria",
    code: "bg",
  },
  {
    title_en: "Burkina Faso",
    title_de: "Burkina Faso",
    title_fr: "Burkina Faso",
    title_it: "Burkina Faso",
    code: "bf",
  },
  {
    title_en: "Burundi",
    title_de: "Burundi",
    title_fr: "Burundi",
    title_it: "Burundi",
    code: "bi",
  },
  {
    title_en: "Cabo Verde",
    title_de: "Kap Verde",
    title_fr: "Cap-Vert",
    title_it: "Capo Verde",
    code: "cv",
  },
  {
    title_en: "Cambodia",
    title_de: "Kambodscha",
    title_fr: "Cambodge",
    title_it: "Cambogia",
    code: "kh",
  },
  {
    title_en: "Cameroon",
    title_de: "Kamerun",
    title_fr: "Cameroun",
    title_it: "Camerun",
    code: "cm",
  },
  {
    title_en: "Canada",
    title_de: "Kanada",
    title_fr: "Canada",
    title_it: "Canada",
    code: "ca",
  },
  {
    title_en: "Central African Republic",
    title_de: "Zentralafrikanische Republik",
    title_fr: "République centrafricaine",
    title_it: "Rep. Centrafricana",
    code: "cf",
  },
  {
    title_en: "Chad",
    title_de: "Tschad",
    title_fr: "Tchad",
    title_it: "Ciad",
    code: "td",
  },
  {
    title_en: "Chile",
    title_de: "Chile",
    title_fr: "Chili",
    title_it: "Cile",
    code: "cl",
  },
  {
    title_en: "China",
    title_de: "China",
    title_fr: "Chine",
    title_it: "Cina",
    code: "cn",
  },
  {
    title_en: "Colombia",
    title_de: "Kolumbien",
    title_fr: "Colombie",
    title_it: "Colombia",
    code: "co",
  },
  {
    title_en: "Comoros",
    title_de: "Komoren",
    title_fr: "Comores",
    title_it: "Comore",
    code: "km",
  },
  {
    title_en: "Congo",
    title_de: "Kongo, Republik",
    title_fr: "République du Congo",
    title_it: "Rep. del Congo",
    code: "cg",
  },
  {
    title_en: "Congo, Democratic Republic of the",
    title_de: "Kongo, Demokratische Republik",
    title_fr: "République démocratique du Congo",
    title_it: "RD del Congo",
    code: "cd",
  },
  {
    title_en: "Costa Rica",
    title_de: "Costa Rica",
    title_fr: "Costa Rica",
    title_it: "Costa Rica",
    code: "cr",
  },
  {
    title_en: "Côte d'Ivoire",
    title_de: "Elfenbeinküste",
    title_fr: "Côte d'Ivoire",
    title_it: "Costa d'Avorio",
    code: "ci",
  },
  {
    title_en: "Croatia",
    title_de: "Kroatien",
    title_fr: "Croatie",
    title_it: "Croazia",
    code: "hr",
  },
  {
    title_en: "Cuba",
    title_de: "Kuba",
    title_fr: "Cuba",
    title_it: "Cuba",
    code: "cu",
  },
  {
    title_en: "Cyprus",
    title_de: "Zypern",
    title_fr: "Chypre (pays)",
    title_it: "Cipro",
    code: "cy",
  },
  {
    title_en: "Czechia",
    title_de: "Tschechien",
    title_fr: "Tchéquie",
    title_it: "Rep. Ceca",
    code: "cz",
  },
  {
    title_en: "Denmark",
    title_de: "Dänemark",
    title_fr: "Danemark",
    title_it: "Danimarca",
    code: "dk",
  },
  {
    title_en: "Djibouti",
    title_de: "Dschibuti",
    title_fr: "Djibouti",
    title_it: "Gibuti",
    code: "dj",
  },
  {
    title_en: "Dominica",
    title_de: "Dominica",
    title_fr: "Dominique",
    title_it: "Dominica",
    code: "dm",
  },
  {
    title_en: "Dominican Republic",
    title_de: "Dominikanische Republik",
    title_fr: "République dominicaine",
    title_it: "Rep. Dominicana",
    code: "do",
  },
  {
    title_en: "Ecuador",
    title_de: "Ecuador",
    title_fr: "Équateur",
    title_it: "Ecuador",
    code: "ec",
  },
  {
    title_en: "Egypt",
    title_de: "Ägypten",
    title_fr: "Égypte",
    title_it: "Egitto",
    code: "eg",
  },
  {
    title_en: "El Salvador",
    title_de: "El Salvador",
    title_fr: "Salvador",
    title_it: "El Salvador",
    code: "sv",
  },
  {
    title_en: "Equatorial Guinea",
    title_de: "Äquatorialguinea",
    title_fr: "Guinée équatoriale",
    title_it: "Guinea Equatoriale",
    code: "gq",
  },
  {
    title_en: "Eritrea",
    title_de: "Eritrea",
    title_fr: "Érythrée",
    title_it: "Eritrea",
    code: "er",
  },
  {
    title_en: "Estonia",
    title_de: "Estland",
    title_fr: "Estonie",
    title_it: "Estonia",
    code: "ee",
  },
  {
    title_en: "Eswatini",
    title_de: "Eswatini",
    title_fr: "Eswatini",
    title_it: "eSwatini",
    code: "sz",
  },
  {
    title_en: "Ethiopia",
    title_de: "Äthiopien",
    title_fr: "Éthiopie",
    title_it: "Etiopia",
    code: "et",
  },
  {
    title_en: "Fiji",
    title_de: "Fidschi",
    title_fr: "Fidji",
    title_it: "Figi",
    code: "fj",
  },
  {
    title_en: "Finland",
    title_de: "Finnland",
    title_fr: "Finlande",
    title_it: "Finlandia",
    code: "fi",
  },
  {
    title_en: "France",
    title_de: "Frankreich",
    title_fr: "France",
    title_it: "Francia",
    code: "fr",
  },
  {
    title_en: "Gabon",
    title_de: "Gabun",
    title_fr: "Gabon",
    title_it: "Gabon",
    code: "ga",
  },
  {
    title_en: "Gambia",
    title_de: "Gambia",
    title_fr: "Gambie",
    title_it: "Gambia",
    code: "gm",
  },
  {
    title_en: "Georgia",
    title_de: "Georgien",
    title_fr: "Géorgie",
    title_it: "Georgia",
    code: "ge",
  },
  {
    title_en: "Germany",
    title_de: "Deutschland",
    title_fr: "Allemagne",
    title_it: "Germania",
    code: "de",
  },
  {
    title_en: "Ghana",
    title_de: "Ghana",
    title_fr: "Ghana",
    title_it: "Ghana",
    code: "gh",
  },
  {
    title_en: "Greece",
    title_de: "Griechenland",
    title_fr: "Grèce",
    title_it: "Grecia",
    code: "gr",
  },
  {
    title_en: "Grenada",
    title_de: "Grenada",
    title_fr: "Grenade",
    title_it: "Grenada",
    code: "gd",
  },
  {
    title_en: "Guatemala",
    title_de: "Guatemala",
    title_fr: "Guatemala",
    title_it: "Guatemala",
    code: "gt",
  },
  {
    title_en: "Guinea",
    title_de: "Guinea",
    title_fr: "Guinée",
    title_it: "Guinea",
    code: "gn",
  },
  {
    title_en: "Guinea-Bissau",
    title_de: "Guinea-Bissau",
    title_fr: "Guinée-Bissau",
    title_it: "Guinea-Bissau",
    code: "gw",
  },
  {
    title_en: "Guyana",
    title_de: "Guyana",
    title_fr: "Guyana",
    title_it: "Guyana",
    code: "gy",
  },
  {
    title_en: "Haiti",
    title_de: "Haiti",
    title_fr: "Haïti",
    title_it: "Haiti",
    code: "ht",
  },
  {
    title_en: "Honduras",
    title_de: "Honduras",
    title_fr: "Honduras",
    title_it: "Honduras",
    code: "hn",
  },
  {
    title_en: "Hungary",
    title_de: "Ungarn",
    title_fr: "Hongrie",
    title_it: "Ungheria",
    code: "hu",
  },
  {
    title_en: "Iceland",
    title_de: "Island",
    title_fr: "Islande",
    title_it: "Islanda",
    code: "is",
  },
  {
    title_en: "India",
    title_de: "Indien",
    title_fr: "Inde",
    title_it: "India",
    code: "in",
  },
  {
    title_en: "Indonesia",
    title_de: "Indonesien",
    title_fr: "Indonésie",
    title_it: "Indonesia",
    code: "id",
  },
  {
    title_en: "Iran",
    title_de: "Iran",
    title_fr: "Iran",
    title_it: "Iran",
    code: "ir",
  },
  {
    title_en: "Iraq",
    title_de: "Irak",
    title_fr: "Irak",
    title_it: "Iraq",
    code: "iq",
  },
  {
    title_en: "Ireland",
    title_de: "Irland",
    title_fr: "Irlande",
    title_it: "Irlanda",
    code: "ie",
  },
  {
    title_en: "Israel",
    title_de: "Israel",
    title_fr: "Israël",
    title_it: "Israele",
    code: "il",
  },
  {
    title_en: "Italy",
    title_de: "Italien",
    title_fr: "Italie",
    title_it: "Italia",
    code: "it",
  },
  {
    title_en: "Jamaica",
    title_de: "Jamaika",
    title_fr: "Jamaïque",
    title_it: "Giamaica",
    code: "jm",
  },
  {
    title_en: "Japan",
    title_de: "Japan",
    title_fr: "Japon",
    title_it: "Giappone",
    code: "jp",
  },
  {
    title_en: "Jordan",
    title_de: "Jordanien",
    title_fr: "Jordanie",
    title_it: "Giordania",
    code: "jo",
  },
  {
    title_en: "Kazakhstan",
    title_de: "Kasachstan",
    title_fr: "Kazakhstan",
    title_it: "Kazakistan",
    code: "kz",
  },
  {
    title_en: "Kenya",
    title_de: "Kenia",
    title_fr: "Kenya",
    title_it: "Kenya",
    code: "ke",
  },
  {
    title_en: "Kiribati",
    title_de: "Kiribati",
    title_fr: "Kiribati",
    title_it: "Kiribati",
    code: "ki",
  },
  {
    title_en: "North Korea",
    title_de: "Nordkorea",
    title_fr: "Corée du Nord",
    title_it: "Corea del Nord",
    code: "kp",
  },
  {
    title_en: "South Korea",
    title_de: "Südkorea",
    title_fr: "Corée du Sud",
    title_it: "Corea del Sud",
    code: "kr",
  },
  {
    title_en: "Kuwait",
    title_de: "Kuwait",
    title_fr: "Koweït",
    title_it: "Kuwait",
    code: "kw",
  },
  {
    title_en: "Kyrgyzstan",
    title_de: "Kirgisistan",
    title_fr: "Kirghizistan",
    title_it: "Kirghizistan",
    code: "kg",
  },
  {
    title_en: "Lao",
    title_de: "Laos",
    title_fr: "Laos",
    title_it: "Laos",
    code: "la",
  },
  {
    title_en: "Latvia",
    title_de: "Lettland",
    title_fr: "Lettonie",
    title_it: "Lettonia",
    code: "lv",
  },
  {
    title_en: "Lebanon",
    title_de: "Libanon",
    title_fr: "Liban",
    title_it: "Libano",
    code: "lb",
  },
  {
    title_en: "Lesotho",
    title_de: "Lesotho",
    title_fr: "Lesotho",
    title_it: "Lesotho",
    code: "ls",
  },
  {
    title_en: "Liberia",
    title_de: "Liberia",
    title_fr: "Liberia",
    title_it: "Liberia",
    code: "lr",
  },
  {
    title_en: "Libya",
    title_de: "Libyen",
    title_fr: "Libye",
    title_it: "Libia",
    code: "ly",
  },
  {
    title_en: "Liechtenstein",
    title_de: "Liechtenstein",
    title_fr: "Liechtenstein",
    title_it: "Liechtenstein",
    code: "li",
  },
  {
    title_en: "Lithuania",
    title_de: "Litauen",
    title_fr: "Lituanie",
    title_it: "Lituania",
    code: "lt",
  },
  {
    title_en: "Luxembourg",
    title_de: "Luxemburg",
    title_fr: "Luxembourg",
    title_it: "Lussemburgo",
    code: "lu",
  },
  {
    title_en: "Madagascar",
    title_de: "Madagaskar",
    title_fr: "Madagascar",
    title_it: "Madagascar",
    code: "mg",
  },
  {
    title_en: "Malawi",
    title_de: "Malawi",
    title_fr: "Malawi",
    title_it: "Malawi",
    code: "mw",
  },
  {
    title_en: "Malaysia",
    title_de: "Malaysia",
    title_fr: "Malaisie",
    title_it: "Malaysia",
    code: "my",
  },
  {
    title_en: "Maldives",
    title_de: "Malediven",
    title_fr: "Maldives",
    title_it: "Maldive",
    code: "mv",
  },
  {
    title_en: "Mali",
    title_de: "Mali",
    title_fr: "Mali",
    title_it: "Mali",
    code: "ml",
  },
  {
    title_en: "Malta",
    title_de: "Malta",
    title_fr: "Malte",
    title_it: "Malta",
    code: "mt",
  },
  {
    title_en: "Mauritania",
    title_de: "Mauretanien",
    title_fr: "Mauritanie",
    title_it: "Mauritania",
    code: "mr",
  },
  {
    title_en: "Mauritius",
    title_de: "Mauritius",
    title_fr: "Maurice",
    title_it: "Mauritius",
    code: "mu",
  },
  {
    title_en: "Mexico",
    title_de: "Mexiko",
    title_fr: "Mexique",
    title_it: "Messico",
    code: "mx",
  },
  {
    title_en: "Moldova",
    title_de: "Moldau",
    title_fr: "Moldavie",
    title_it: "Moldavia",
    code: "md",
  },
  {
    title_en: "Monaco",
    title_de: "Monaco",
    title_fr: "Monaco",
    title_it: "Monaco",
    code: "mc",
  },
  {
    title_en: "Mongolia",
    title_de: "Mongolei",
    title_fr: "Mongolie",
    title_it: "Mongolia",
    code: "mn",
  },
  {
    title_en: "Montenegro",
    title_de: "Montenegro",
    title_fr: "Monténégro",
    title_it: "Montenegro",
    code: "me",
  },
  {
    title_en: "Morocco",
    title_de: "Marokko",
    title_fr: "Maroc",
    title_it: "Marocco",
    code: "ma",
  },
  {
    title_en: "Mozambique",
    title_de: "Mosambik",
    title_fr: "Mozambique",
    title_it: "Mozambico",
    code: "mz",
  },
  {
    title_en: "Myanmar",
    title_de: "Myanmar",
    title_fr: "Birmanie",
    title_it: "Birmania",
    code: "mm",
  },
  {
    title_en: "Namibia",
    title_de: "Namibia",
    title_fr: "Namibie",
    title_it: "Namibia",
    code: "na",
  },
  {
    title_en: "Nauru",
    title_de: "Nauru",
    title_fr: "Nauru",
    title_it: "Nauru",
    code: "nr",
  },
  {
    title_en: "Nepal",
    title_de: "Nepal",
    title_fr: "Népal",
    title_it: "Nepal",
    code: "np",
  },
  {
    title_en: "Netherlands",
    title_de: "Niederlande",
    title_fr: "Pays-Bas",
    title_it: "Paesi Bassi",
    code: "nl",
  },
  {
    title_en: "New Zealand",
    title_de: "Neuseeland",
    title_fr: "Nouvelle-Zélande",
    title_it: "Nuova Zelanda",
    code: "nz",
  },
  {
    title_en: "Nicaragua",
    title_de: "Nicaragua",
    title_fr: "Nicaragua",
    title_it: "Nicaragua",
    code: "ni",
  },
  {
    title_en: "Niger",
    title_de: "Niger",
    title_fr: "Niger",
    title_it: "Niger",
    code: "ne",
  },
  {
    title_en: "Nigeria",
    title_de: "Nigeria",
    title_fr: "Nigeria",
    title_it: "Nigeria",
    code: "ng",
  },
  {
    title_en: "North Macedonia",
    title_de: "Nordmazedonien",
    title_fr: "Macédoine du Nord",
    title_it: "Macedonia del Nord",
    code: "mk",
  },
  {
    title_en: "Norway",
    title_de: "Norwegen",
    title_fr: "Norvège",
    title_it: "Norvegia",
    code: "no",
  },
  {
    title_en: "Oman",
    title_de: "Oman",
    title_fr: "Oman",
    title_it: "Oman",
    code: "om",
  },
  {
    title_en: "Pakistan",
    title_de: "Pakistan",
    title_fr: "Pakistan",
    title_it: "Pakistan",
    code: "pk",
  },
  {
    title_en: "Palau",
    title_de: "Palau",
    title_fr: "Palaos",
    title_it: "Palau",
    code: "pw",
  },
  {
    title_en: "Panama",
    title_de: "Panama",
    title_fr: "Panama",
    title_it: "Panama",
    code: "pa",
  },
  {
    title_en: "Papua New Guinea",
    title_de: "Papua-Neuguinea",
    title_fr: "Papouasie-Nouvelle-Guinée",
    title_it: "Papua Nuova Guinea",
    code: "pg",
  },
  {
    title_en: "Paraguay",
    title_de: "Paraguay",
    title_fr: "Paraguay",
    title_it: "Paraguay",
    code: "py",
  },
  {
    title_en: "Peru",
    title_de: "Peru",
    title_fr: "Pérou",
    title_it: "Perù",
    code: "pe",
  },
  {
    title_en: "Philippines",
    title_de: "Philippinen",
    title_fr: "Philippines",
    title_it: "Filippine",
    code: "ph",
  },
  {
    title_en: "Poland",
    title_de: "Polen",
    title_fr: "Pologne",
    title_it: "Polonia",
    code: "pl",
  },
  {
    title_en: "Portugal",
    title_de: "Portugal",
    title_fr: "Portugal",
    title_it: "Portogallo",
    code: "pt",
  },
  {
    title_en: "Qatar",
    title_de: "Katar",
    title_fr: "Qatar",
    title_it: "Qatar",
    code: "qa",
  },
  {
    title_en: "Romania",
    title_de: "Rumänien",
    title_fr: "Roumanie",
    title_it: "Romania",
    code: "ro",
  },
  {
    title_en: "Russian Federation",
    title_de: "Russland",
    title_fr: "Russie",
    title_it: "Russia",
    code: "ru",
  },
  {
    title_en: "Rwanda",
    title_de: "Ruanda",
    title_fr: "Rwanda",
    title_it: "Ruanda",
    code: "rw",
  },
  {
    title_en: "Samoa",
    title_de: "Samoa",
    title_fr: "Samoa",
    title_it: "Samoa",
    code: "ws",
  },
  {
    title_en: "San Marino",
    title_de: "San Marino",
    title_fr: "Saint-Marin",
    title_it: "San Marino",
    code: "sm",
  },
  {
    title_en: "Sao Tome and Principe",
    title_de: "São Tomé und Príncipe",
    title_fr: "Sao Tomé-et-Principe",
    title_it: "São Tomé e Príncipe",
    code: "st",
  },
  {
    title_en: "Saudi Arabia",
    title_de: "Saudi-Arabien",
    title_fr: "Arabie saoudite",
    title_it: "Arabia Saudita",
    code: "sa",
  },
  {
    title_en: "Senegal",
    title_de: "Senegal",
    title_fr: "Sénégal",
    title_it: "Senegal",
    code: "sn",
  },
  {
    title_en: "Serbia",
    title_de: "Serbien",
    title_fr: "Serbie",
    title_it: "Serbia",
    code: "rs",
  },
  {
    title_en: "Seychelles",
    title_de: "Seychellen",
    title_fr: "Seychelles",
    title_it: "Seychelles",
    code: "sc",
  },
  {
    title_en: "Sierra Leone",
    title_de: "Sierra Leone",
    title_fr: "Sierra Leone",
    title_it: "Sierra Leone",
    code: "sl",
  },
  {
    title_en: "Singapore",
    title_de: "Singapur",
    title_fr: "Singapour",
    title_it: "Singapore",
    code: "sg",
  },
  {
    title_en: "Slovakia",
    title_de: "Slowakei",
    title_fr: "Slovaquie",
    title_it: "Slovacchia",
    code: "sk",
  },
  {
    title_en: "Slovenia",
    title_de: "Slowenien",
    title_fr: "Slovénie",
    title_it: "Slovenia",
    code: "si",
  },
  {
    title_en: "Somalia",
    title_de: "Somalia",
    title_fr: "Somalie",
    title_it: "Somalia",
    code: "so",
  },
  {
    title_en: "South Africa",
    title_de: "Südafrika",
    title_fr: "Afrique du Sud",
    title_it: "Sudafrica",
    code: "za",
  },
  {
    title_en: "South Sudan",
    title_de: "Südsudan",
    title_fr: "Soudan du Sud",
    title_it: "Sudan del Sud",
    code: "ss",
  },
  {
    title_en: "Spain",
    title_de: "Spanien",
    title_fr: "Espagne",
    title_it: "Spagna",
    code: "es",
  },
  {
    title_en: "Sri Lanka",
    title_de: "Sri Lanka",
    title_fr: "Sri Lanka",
    title_it: "Sri Lanka",
    code: "lk",
  },
  {
    title_en: "Sudan",
    title_de: "Sudan",
    title_fr: "Soudan",
    title_it: "Sudan",
    code: "sd",
  },
  {
    title_en: "Suriname",
    title_de: "Suriname",
    title_fr: "Suriname",
    title_it: "Suriname",
    code: "sr",
  },
  {
    title_en: "Sweden",
    title_de: "Schweden",
    title_fr: "Suède",
    title_it: "Svezia",
    code: "se",
  },
  {
    title_en: "Switzerland",
    title_de: "Schweiz",
    title_fr: "Suisse",
    title_it: "Svizzera",
    code: "ch",
  },
  {
    title_en: "Syria",
    title_de: "Syrien",
    title_fr: "Syrie",
    title_it: "Siria",
    code: "sy",
  },
  {
    title_en: "Tajikistan",
    title_de: "Tadschikistan",
    title_fr: "Tadjikistan",
    title_it: "Tagikistan",
    code: "tj",
  },
  {
    title_en: "Tanzania",
    title_de: "Tansania",
    title_fr: "Tanzanie",
    title_it: "Tanzania",
    code: "tz",
  },
  {
    title_en: "Thailand",
    title_de: "Thailand",
    title_fr: "Thaïlande",
    title_it: "Thailandia",
    code: "th",
  },
  {
    title_en: "Timor-Leste",
    title_de: "Osttimor",
    title_fr: "Timor oriental",
    title_it: "Timor Est",
    code: "tl",
  },
  {
    title_en: "Togo",
    title_de: "Togo",
    title_fr: "Togo",
    title_it: "Togo",
    code: "tg",
  },
  {
    title_en: "Tonga",
    title_de: "Tonga",
    title_fr: "Tonga",
    title_it: "Tonga",
    code: "to",
  },
  {
    title_en: "Trinidad and Tobago",
    title_de: "Trinidad und Tobago",
    title_fr: "Trinité-et-Tobago",
    title_it: "Trinidad e Tobago",
    code: "tt",
  },
  {
    title_en: "Tunisia",
    title_de: "Tunesien",
    title_fr: "Tunisie",
    title_it: "Tunisia",
    code: "tn",
  },
  {
    title_en: "Turkey",
    title_de: "Türkei",
    title_fr: "Turquie",
    title_it: "Turchia",
    code: "tr",
  },
  {
    title_en: "Turkmenistan",
    title_de: "Turkmenistan",
    title_fr: "Turkménistan",
    title_it: "Turkmenistan",
    code: "tm",
  },
  {
    title_en: "Tuvalu",
    title_de: "Tuvalu",
    title_fr: "Tuvalu",
    title_it: "Tuvalu",
    code: "tv",
  },
  {
    title_en: "Uganda",
    title_de: "Uganda",
    title_fr: "Ouganda",
    title_it: "Uganda",
    code: "ug",
  },
  {
    title_en: "Ukraine",
    title_de: "Ukraine",
    title_fr: "Ukraine",
    title_it: "Ucraina",
    code: "ua",
  },
  {
    title_en: "United Arab Emirates",
    title_de: "Vereinigte Arabische Emirate",
    title_fr: "Émirats arabes unis",
    title_it: "Emirati Arabi Uniti",
    code: "ae",
  },
  {
    title_en: "United Kingdom",
    title_de: "Vereinigtes Königreich",
    title_fr: "Royaume-Uni",
    title_it: "Regno Unito",
    code: "gb",
  },
  {
    title_en: "United States of America",
    title_de: "Vereinigte Staaten",
    title_fr: "États-Unis",
    title_it: "Stati Uniti",
    code: "us",
  },
  {
    title_en: "Uruguay",
    title_de: "Uruguay",
    title_fr: "Uruguay",
    title_it: "Uruguay",
    code: "uy",
  },
  {
    title_en: "Uzbekistan",
    title_de: "Usbekistan",
    title_fr: "Ouzbékistan",
    title_it: "Uzbekistan",
    code: "uz",
  },
  {
    title_en: "Vanuatu",
    title_de: "Vanuatu",
    title_fr: "Vanuatu",
    title_it: "Vanuatu",
    code: "vu",
  },
  {
    title_en: "Venezuela",
    title_de: "Venezuela",
    title_fr: "Venezuela",
    title_it: "Venezuela",
    code: "ve",
  },
  {
    title_en: "Vietnam",
    title_de: "Vietnam",
    title_fr: "Viêt Nam",
    title_it: "Vietnam",
    code: "vn",
  },
  {
    title_en: "Yemen",
    title_de: "Jemen",
    title_fr: "Yémen",
    title_it: "Yemen",
    code: "ye",
  },
  {
    title_en: "Zambia",
    title_de: "Sambia",
    title_fr: "Zambie",
    title_it: "Zambia",
    code: "zm",
  },
  {
    title_en: "Zimbabwe",
    title_de: "Simbabwe",
    title_fr: "Zimbabwe",
    title_it: "Zimbabwe",
    code: "zw",
  },
];

export default dialectCountries;
