import moment from "moment";
import ApiService from "./ApiService";
import {
  IInterpreterEditForm,
  IFilterItems,
  IInterpreterUser,
  IStripeStatus,
  ISessionInterpreter,
  VacationTime,
} from "./interfaces";

class InterpreterService extends ApiService {
  public async getInterpreters({
    current,
    pageSize,
    status,
    firstName,
    lastName,
    onboarded,
    email,
    nativeLanguages,
    workingLanguages,
    ratingRange,
  }: IFilterItems): Promise<{
    interpreters: IInterpreterUser[];
    count: number;
  }> {
    const offset = ((current as number) - 1) * (pageSize as number);
    let url = `/interpreter?offset=${offset}`;
    if (pageSize) url += `&limit=${pageSize}`;
    if (status) url += `&status=${status}`;
    if (firstName) url += `&firstName=${firstName}`;
    if (lastName) url += `&lastName=${lastName}`;
    if (email) url += `&email=${email}`;
    if (onboarded) url += `&onboarded=${onboarded}`;
    if (nativeLanguages) url += `&nativeLanguages=${nativeLanguages}`;
    if (workingLanguages) url += `&workingLanguages=${workingLanguages}`;
    if (ratingRange?.length && (ratingRange[0] !== 0 || ratingRange[1] !== 5))
      url += `&ratingFrom=${ratingRange[0]}&ratingTo=${ratingRange[1]}`;

    const response = await this.request<{
      interpreters: IInterpreterUser[];
      count: number;
    }>({
      url,
    });

    return response.data;
  }

  public async getInterpreter(id: string): Promise<IInterpreterUser> {
    const response = await this.request({
      url: `interpreter/${id}`,
    });

    return response.data;
  }

  public async getInterpreterStripeStatus(id: string): Promise<IStripeStatus> {
    const response = await this.request({
      url: `interpreter/get-stripe/${id}`,
    });

    return response.data;
  }

  public async updateInterpreterStatus(
    id: string,
    status: boolean
  ): Promise<IInterpreterUser> {
    const response = await this.request({
      url: `interpreter/${id}/status`,
      method: "PATCH",
      data: {
        isActive: status,
      },
    });

    return response.data;
  }

  public async updateInterpreter(data: IInterpreterEditForm) {
    const { profileId } = data;
    await this.request({
      url: `interpreter/${profileId}`,
      method: "PUT",
      data: {
        ...data,
      },
    });
  }

  public async loginAsInterpreter(email: string, password: string) {
    const response = await this.request({
      url: "auth/super-login",
      method: "POST",
      data: {
        email,
        password,
      },
    });

    return response.data;
  }

  public async getInterpretersForSession(
    translateFrom: string,
    expertiseArea: number,
    gender?: "male" | "female",
    translateTo?: string,
    translateToSign?: string
  ): Promise<ISessionInterpreter[]> {
    const response = await this.request({
      url: `videosessions/available-interpreters`,
      method: "POST",
      data: {
        translateFrom,
        expertiseArea,
        gender,
        translateTo,
        translateToSign,
      },
    });

    return response.data;
  }

  public async addVacation(
    interpreterId: number,
    data: {
      start: string;
      end: string;
    }
  ): Promise<VacationTime> {
    const response = await this.request<VacationTime>({
      url: `/interpreter/vacations/${interpreterId}`,
      method: "post",
      data,
    });

    return response.data;
  }

  public async getVacations(interpreterId: number): Promise<VacationTime[]> {
    const response = await this.request<VacationTime[]>({
      url: `/interpreter/vacations/${interpreterId}`,
      method: "get",
    });

    return response.data;
  }

  public async removeVacation(interpreterId: number, vacationId: number) {
    await this.request({
      url: `/interpreter/vacations/${interpreterId}/${vacationId}`,
      method: "delete",
    });
  }

  public async changeInterpreterWebsite(
    interpreterId: number,
    website?: string
  ) {
    await this.request({
      url: `/interpreter/website/${interpreterId}`,
      method: "put",
      data: { website },
    });
  }

  public async getAvailableInterpreters({
    expertiseArea,
    startDate,
    startTime,
    timeRange,
    dialect,
    gender,
    translateFrom,
    translateTo,
    translateSign,
    type,
  }: IFilterItems): Promise<IInterpreterUser[]> {
    const time = moment(startTime).format("HH:mm").split(":");
    const date = moment(startDate).format("D-MM-yyyy").split("-");

    const startTimeToSend = moment()
      .set("date", +date[0])
      .set("month", +date[1] - 1)
      .set("year", +date[2])
      .set("hour", +time[0])
      .set("minute", +time[1])
      .set("second", 0)
      .toISOString();

    let url = "/interpreter/available?";
    if (expertiseArea) url += `&expertiseArea=${expertiseArea}`;
    if (startTimeToSend) url += `&startTime=${startTimeToSend}`;
    if (timeRange) url += `&timeRange=${timeRange}`;
    if (dialect) url += `&dialect=${dialect}`;
    if (gender) url += `&gender=${gender}`;
    if (translateFrom) url += `&translateFrom=${translateFrom}`;
    if (translateTo && type === "spoken") url += `&translateTo=${translateTo}`;
    if (translateSign && type === "sign")
      url += `&translateSign=${translateSign}`;

    const response = await this.request<IInterpreterUser[]>({
      url,
    });

    return response.data;
  }

  public async deleteInterpreter(
    id: string | number,
    code: string,
    secret: string
  ): Promise<void> {
    const response = await this.request({
      url: `interpreter/${id}?code=${code}&secret=${secret}`,
      method: "delete",
    });

    return response.data;
  }
}

const instance = new InterpreterService();

export default instance;
