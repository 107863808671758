import React, { useState } from "react";
import { Button, Col, InputNumber, Modal, Row, Slider } from "antd";

import "./index.scss";
import { transformPriceFormatter } from "../../../helpers/transform";

type PropTypes = {
  show: boolean;
  handleClose: () => void;
  confirm: (
    amount: number,
    setSubmitting: (isSubmitting: boolean) => void,
    clearState: () => void
  ) => void;
  currentDiscount: number;
  maxDiscount: number;
};

const DiscountSpending: React.FC<PropTypes> = ({
  show,
  handleClose,
  confirm,
  currentDiscount,
  maxDiscount,
}) => {
  const [discount, setDiscount] = useState(0);
  const [submitting, setSubmitting] = useState(false);

  const formatter = (value: number | undefined) => {
    return `${
      currentDiscount ? transformPriceFormatter(currentDiscount) + "CHF + " : ""
    }${transformPriceFormatter(value && value * 100)}CHF`;
  };

  const handleConfirm = () => {
    confirm(discount * 100, setSubmitting, () => setDiscount(0));
  };
  return (
    <Modal
      title="Discount spending:"
      visible={show}
      onCancel={() => {
        setDiscount(0);
        handleClose();
      }}
      footer={null}
    >
      <div className="wrapper">
        <Row style={{ width: 300, paddingTop: "1rem" }}>
          <Col span={18}>
            <Slider
              min={0}
              step={0.01}
              max={(maxDiscount - currentDiscount) / 100}
              tipFormatter={formatter}
              value={discount}
              onChange={setDiscount}
              tooltipVisible
            />
          </Col>
          <Col span={6}>
            <InputNumber
              min={0}
              max={maxDiscount - currentDiscount}
              step="0.01"
              style={{ maxWidth: 100, marginLeft: 15 }}
              value={discount}
              onChange={(v) =>
                v < maxDiscount - currentDiscount && setDiscount(v)
              }
            />
          </Col>
        </Row>

        <Button
          disabled={discount > maxDiscount}
          onClick={handleConfirm}
          type="primary"
          loading={submitting}
        >
          Update
        </Button>
      </div>
    </Modal>
  );
};

export default DiscountSpending;
