import { ILanguage } from "../utils/interfaces";

export const languageSelector = (
  code: string,
  spoken: ILanguage[],
  sign: ILanguage[]
) => {
  return code.length === 2
    ? spoken.find((lang) => lang.code === code)?.title_en
    : sign.find((lang) => lang.code === code)?.title_en;
};
