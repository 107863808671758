import React, { Key, useEffect, useRef, useState } from "react";
import { Button, Input, Space, Table, TablePaginationConfig, DatePicker } from "antd";
import Highlighter from "react-highlight-words";
import VideosessionService from "../../utils/VideosessionService";
import {
  IFilterItems,
  IVideosession,
  VideosessionStatus,
} from "../../utils/interfaces";
import { FilterDropdownProps } from "antd/lib/table/interface";
import { SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

interface IVideosessionItem extends IVideosession {
  key: number;
  startDate: string;
}

const Videosessions: React.FC = () => {
  const [dataSource, setDataSource] = useState<IVideosessionItem[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [range, setRange] = useState<{ from: string, to: string }>()
  const [filter, setFilter] = useState<IFilterItems>({
    current: 1,
    pageSize: 10,
    email: "",
    companyName: "",
    interpreterEmail: "",
    status: "",
    from: undefined,
    to: undefined
  });
  const [state, setState] = useState({ searchText: "", searchedColumn: "" });
  const emailSearchInput = useRef<Input>(null);
  const interpreterEmailSearchInput = useRef<Input>(null);
  const companyNameSearchInput = useRef<Input>(null);

  useEffect(() => {
    const getVideosessions = async () => {
      if (!filter.current || !filter.pageSize) return;
      setLoading(true);

      const { videosessions, count } =
        await VideosessionService.getVideosessions(filter);

      setTotalCount(count);
      const dataToSet: IVideosessionItem[] = videosessions.map(
        (videosession) => {
          const videosessionItem: IVideosessionItem = {
            key: videosession.id,
            ...videosession,
            startDate: new Date(videosession.startTime).toLocaleDateString(
              "de-CH"
            ),
            costCenter: videosession.costCenter || "-",
            interpreter: {
              ...videosession.interpreter,
              email: videosession.interpreter?.email || "-",
            },
          };
          return videosessionItem;
        }
      );
      setDataSource(dataToSet);

      setLoading(false);
    };
    getVideosessions();
  }, [filter]);

  const handleTableChange = ({
    pagination,
    filters,
  }: {
    pagination: TablePaginationConfig;
    filters: any;
  }) => {
    const { current, pageSize } = pagination;
    let statusToSet = null;
    if (!!filters?.status?.length) {
      statusToSet = filters.status.join("-");
    }
    setFilter({
      ...filter,
      current: current as number,
      pageSize: pageSize as number,
      status: statusToSet,
      from: range?.from,
      to: range?.to,
      [state.searchedColumn]: state.searchText,
    });
  };

  const handleSearch = (
    selectedKeys: Key[],
    confirm: () => void,
    dataIndex: string
  ) => {
    confirm();
    setState({
      searchText: selectedKeys[0] as string,
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters: (() => void) | undefined) => {
    if (!clearFilters) return;
    clearFilters();
    setState({ searchText: "", searchedColumn: "" });
  };

  const getRefToSet = (dataIndex: string) => {
    switch (dataIndex) {
      case "interpreterEmail":
        return interpreterEmailSearchInput;
      case "companyName":
        return companyNameSearchInput;
      case "email":
        return emailSearchInput;
    }
  };

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: FilterDropdownProps) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={getRefToSet(dataIndex)}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            setState({
              searchText: e.target.value,
              searchedColumn: dataIndex,
            });
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            onClick={() => {
              setState({ searchText: "", searchedColumn: "" });

              handleReset(clearFilters);
              setTimeout(() => getRefToSet(dataIndex)?.current?.focus(), 100);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => getRefToSet(dataIndex)?.current?.focus(), 100);
      }
    },
    render: (text: string) =>
      state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const getColumnDateRangeProps = (dataIndex: string) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: FilterDropdownProps) => (
      <div style={{ padding: 8 }}>
        <DatePicker.RangePicker
          onChange={(dates) => {
            const from = dates?.[0]?.startOf('day').toISOString();
            const to = dates?.[1]?.endOf('day').toISOString();
            if (from && to) {
              setRange({
                from, to
              })
            }
          }}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            onClick={() => {
              handleReset(clearFilters);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="primary"
            onClick={() => { setFilter({ ...filter, from: range?.from, to: range?.to }); confirm() }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
        </Space>
      </div>
    ),
  });


  const columns = [
    {
      title: "Start date",
      dataIndex: "startDate",
      ...getColumnDateRangeProps("startDate")
    },
    {
      title: "Company Name",
      dataIndex: ["company", "name"],
      ...getColumnSearchProps("companyName"),
    },
    {
      title: "Creator Email",
      dataIndex: ["creator", "email"],
      ...getColumnSearchProps("email"),
    },
    {
      title: "Interpreter Email",
      dataIndex: ["interpreter", "email"],
      ...getColumnSearchProps("interpreterEmail"),
    },
    {
      title: "Language Pair",
      dataIndex: "languagePair",
    },
    {
      title: "Status",
      dataIndex: "status",
      filters: Object.values(VideosessionStatus).map((status) => ({
        text: status,
        value: status,
      })),
    },
    {
      title: "Cost Center",
      dataIndex: "costCenter",
    },
    {
      title: "Title",
      dataIndex: "title",
    },
    {
      title: "Details",
      dataIndex: "key",
      fixed: "right",
      width: 80,
      render: (id: string) => (
        <Link to={`/admin/videosessions/${id}`}>Details</Link>
      ),
    },
  ];

  return (
    <div className="App">
      <div style={{ marginBottom: "1rem" }}>
        <span style={{ marginRight: "2rem" }}>
          <b>Videosessions</b>
        </span>
        <Link to="/admin/videosessions/new">
          <Button>New session</Button>
        </Link>
      </div>
      <Table
        dataSource={dataSource}
        columns={columns as any}
        scroll={{ x: 1000 }}
        pagination={{
          ...filter,
          total: totalCount,
          pageSizeOptions: ["10", "25", "50"],
          showSizeChanger: true,
        }}
        loading={loading}
        onChange={(pagination, filters) =>
          handleTableChange({ pagination, filters })
        }
      />
    </div>
  );
};

export default Videosessions;
