import ApiService from "./ApiService";
import { ICertificate } from "./interfaces";

class FileService extends ApiService {
  public async deleteCertificate(certificateId: number, interpreterId: number) {
    await this.request({
      url: `files/certificates/${certificateId}/${interpreterId}`,
      method: "DELETE",
    });
  }

  public async uploadCertificate(
    file: File,
    type: string,
    userId: number
  ): Promise<ICertificate> {
    const formData = new FormData();
    formData.append("type", type);
    formData.append("file", file);
    const response = await this.request({
      url: `files/certificates/${userId}`,
      headers: { "Content-Type": "multipart/form-data" },
      method: "POST",
      data: formData,
    });

    return response.data;
  }

  // public async deleteStatusProof(statusProofId: number, interpreterId: number) {
  //   await this.request({
  //     url: `files/status-proofs/${statusProofId}/${interpreterId}`,
  //     method: "DELETE",
  //   });
  // }

  public async uploadStatusProof(
    file: File,
    profileId: number
  ): Promise<ICertificate> {
    const formData = new FormData();
    formData.append("file", file);
    const response = await this.request({
      url: `files/status-proofs/${profileId}`,
      headers: { "Content-Type": "multipart/form-data" },
      method: "POST",
      data: formData,
    });

    return response.data;
  }

  public async downloadMonthlyInvoice(fileName: string, postfix?: string) {
    const response = await this.request({
      url: `files/monthly-invoice/admin/${fileName}`,
      headers: {
        "Content-Type": "application/pdf",
      },
      responseType: "blob",
    });

    const file = new File([response.data], `${fileName}.pdf`);
    const url = window.URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = url;
    const [year, month] = fileName.split("-");
    link.setAttribute(
      "download",
      `${year}-${month}${postfix ? "-" + postfix : ""}-invoice.pdf`
    );
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode?.removeChild(link);
  }
}

const instance = new FileService();

export default instance;
