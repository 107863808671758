import React, { useRef } from "react";
import { Button, Modal } from "antd";
import { ErrorMessage, FieldArray, Formik, FormikProps } from "formik";
import Text from "antd/lib/typography/Text";
import * as Yup from "yup";

import { Form } from "formik-antd";
import { IAdditionalOption } from "../../../utils/interfaces";
import AdditionalField from "../../AdditionalField";
import additionalFields from "../../../utils/additionalFields";

type PropTypes = {
  show: boolean;
  currentAdditionalFields?: IAdditionalOption[];
  medicineExpertiseId: number;
  handleClose: () => void;
  confirm: (
    value: IAdditionalOption[] | undefined,
    setSubmitting: (isSubmitting: boolean) => void,
    clearState: () => void
  ) => void;
};

const UpdateVideosessionAdditionalFields: React.FC<PropTypes> = ({
  show,
  currentAdditionalFields,
  medicineExpertiseId,
  handleClose,
  confirm,
}) => {
  const formikRef =
    useRef<FormikProps<{ additionalFields: IAdditionalOption[] }>>(null);

  const validationSchema = Yup.object().shape({
    additionalFields: Yup.array()
      .when("expertiseArea", {
        is: (val: number) => {
          return val === medicineExpertiseId;
        },
        then: Yup.array().of(
          Yup.object().test("additional-field-check", "Required", (v) => {
            return !(v.required && !(v.value as string)?.length);
          })
        ),
      })
      .of(
        Yup.object().test(
          "additional-field-length",
          "Maximum 250 characters allowed",
          (v) => {
            return !(
              (v.value as string)?.length && (v.value as string)?.length > 250
            );
          }
        )
      ),
  });

  const findFieldValue = (field: string): string =>
    currentAdditionalFields?.find((f) => f.name === field)?.value || "";

  const initialAdditionalFields = additionalFields.map((f) => ({
    name: f.name,
    required: f.required,
    title: f.title,
    value: findFieldValue(f.name),
  }));

  return (
    <Modal
      title="Update videosession creator:"
      visible={show}
      onCancel={() => {
        handleClose();
        formikRef.current?.resetForm();
      }}
      footer={null}
    >
      <div className="createVoucher">
        <Formik
          innerRef={formikRef}
          initialValues={{
            additionalFields: initialAdditionalFields,
          }}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={(values, { setSubmitting, resetForm }) => {
            const updatedAdditionalFields = values.additionalFields?.length
              ? values.additionalFields
                  .filter((f) => !!f.value?.length)
                  .map((field) => ({
                    name: field.name,
                    value: field.value,
                    title: field.title,
                  }))
              : undefined;
            confirm(updatedAdditionalFields, setSubmitting, resetForm);
          }}
        >
          {({ isSubmitting, submitForm }) => (
            <Form>
              <div className="dateGroup">
                <Text strong>Change creator:</Text>
                <FieldArray
                  name="additionalFields"
                  render={() => (
                    <>
                      {additionalFields.map((addField, i) => (
                        <div className="inputGroup">
                          <AdditionalField
                            index={i}
                            title={addField.title}
                            options={addField.options}
                            placeholder={addField.placeholder}
                            language={"en"}
                            required={addField.required}
                            value={findFieldValue(addField.name)}
                          />
                          <ErrorMessage
                            name={addField.name}
                            component="p"
                            className="error"
                          />
                        </div>
                      ))}
                    </>
                  )}
                />

                <ErrorMessage name="value" component="p" className="error" />
              </div>
              <Button
                className="saveBtn"
                onClick={submitForm}
                disabled={isSubmitting}
                loading={isSubmitting}
                type="primary"
              >
                Update
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default UpdateVideosessionAdditionalFields;
