import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  message,
  Popconfirm,
  Row,
  Table,
  TablePaginationConfig,
  Typography,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { transformPrice } from "../../helpers/transform";
import VoucherService from "../../utils/VoucherService";
import { IFilterItems, IVoucher } from "../../utils/interfaces";
import CreateVoucher from "../../components/VoucherModals/CreateVoucher";
import VoucherDetails from "../../components/VoucherModals/VoucherDetails";
import ActivateVoucher from "../../components/VoucherModals/ActivateVoucher";

import "./index.scss";

const { Title } = Typography;

const Vouchers: React.FC = () => {
  const [dataSource, setDataSource] = useState<IVoucher[]>([]);
  const [modal, setModal] = useState({
    type: "",
    id: 0,
  });
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [filter, setFilter] = useState<IFilterItems>({
    current: 1,
    pageSize: 10,
    status: "ALL",
  });

  useEffect(() => {
    const getVouchers = async () => {
      if (!filter.current || !filter.pageSize) return;
      setLoading(true);

      const { vouchers, count } = await VoucherService.getVouchers(filter);

      setTotalCount(count);

      setDataSource(vouchers);

      setLoading(false);
    };
    getVouchers();
  }, [filter]);

  const handleTableChange = ({
    pagination,
    filters,
  }: {
    pagination: TablePaginationConfig;
    filters: any;
  }) => {
    const { current, pageSize } = pagination;
    let statusToSet = "ALL";
    if (filters?.status?.[0]) {
      statusToSet = filters.status[0];
    }

    setFilter({
      ...filter,
      current: current as number,
      pageSize: pageSize as number,
      status: statusToSet,
    });
  };

  const columns = [
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Valid until",
      dataIndex: "status",
      key: "status",
      filters: ["ALL", "ACTIVE", "EXPIRED"].map((status) => ({
        text: status,
        value: status,
      })),
      filteredValue: [filter.status as string] || null,
      filterMultiple: false,
      render: (status: string, data: IVoucher) => {
        return new Date(data.validBefore).toLocaleString();
      },
    },
    {
      title: "Bonus Amount",
      dataIndex: "bonusAmount",
      key: "bonusAmount",
      render: (bonusAmount: number) => `${transformPrice(bonusAmount)} CHF`,
    },
    {
      title: "Maximum number of uses",
      dataIndex: "maxRedeems",
      key: "maxRedeems",
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      render: (id: number, data: IVoucher) => (
        <>
          {data.validBefore > new Date().toISOString() ? (
            <Popconfirm title="Title" onConfirm={() => deactivateVoucher(id)}>
              <Button danger type="link">
                Deactivate
              </Button>
            </Popconfirm>
          ) : (
            <Button
              onClick={() =>
                setModal({
                  type: "ACTIVATE",
                  id,
                })
              }
              style={{ color: "green" }}
              type="link"
            >
              Activate
            </Button>
          )}
          <Button
            onClick={() =>
              setModal({
                type: "DETAILS",
                id,
              })
            }
            type="link"
          >
            Details
          </Button>
        </>
      ),
    },
  ];

  const handleClose = () => {
    setModal({
      ...modal,
      type: "",
    });
  };

  const handleError = (err: any) => {
    if (err.response?.data?.message) {
      message.error(err.response?.data?.message);
    } else {
      message.error("Something went wrong");
    }
  };

  const handleConfirm = async (
    values: IVoucher,
    setSubmitting: (isSubmitting: boolean) => void,
    clearState: () => void
  ) => {
    setSubmitting(true);

    try {
      const response = await VoucherService.createVoucher(values);

      const vouchersToSet = dataSource;
      vouchersToSet.push(response);
      setDataSource(vouchersToSet);
      clearState();
      handleClose();
    } catch (err: any) {
      handleError(err);
    } finally {
      setSubmitting(false);
    }
  };

  const handleVoucherUpdate = (newVoucher: IVoucher) => {
    const vouchersToSet = dataSource;
    for (const voucher of vouchersToSet) {
      if (voucher.id === newVoucher.id) {
        voucher.validBefore = newVoucher.validBefore;
        break;
      }
    }

    setDataSource([...vouchersToSet]);
  };

  const deactivateVoucher = async (id: number) => {
    const date = new Date().toISOString();
    const updatedVoucher = await VoucherService.updateVoucherStatus(id, date);

    handleVoucherUpdate(updatedVoucher);
  };

  const activateVoucher = async (
    date: string,
    setSubmitting: (isSubmitting: boolean) => void,
    clearState: () => void
  ) => {
    setSubmitting(true);

    try {
      const updatedVoucher = await VoucherService.updateVoucherStatus(
        modal.id,
        date
      );

      handleVoucherUpdate(updatedVoucher);
      clearState();
      handleClose();
      message.success("Voucher successfully activated!");
    } catch (err) {
      handleError(err);
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <div className="voucherPage">
      <Title level={2}>Vouchers</Title>
      <Row>
        <Col span={24}>
          <Button
            onClick={() =>
              setModal({
                type: "CREATE",
                id: 0,
              })
            }
            type="primary"
          >
            <PlusOutlined /> Add voucher
          </Button>
        </Col>
      </Row>
      <Table
        key={`vouchers_${dataSource.length}`}
        dataSource={dataSource}
        columns={columns}
        pagination={{
          ...filter,
          total: totalCount,
          pageSizeOptions: ["10", "25", "50"],
          showSizeChanger: true,
        }}
        loading={loading}
        onChange={(pagination, filters) =>
          handleTableChange({ pagination, filters })
        }
      />

      <CreateVoucher
        show={modal.type === "CREATE"}
        confirm={handleConfirm}
        handleClose={handleClose}
      />
      <VoucherDetails
        show={modal.type === "DETAILS"}
        id={modal.id}
        handleClose={handleClose}
      />
      <ActivateVoucher
        show={modal.type === "ACTIVATE"}
        handleClose={handleClose}
        confirm={activateVoucher}
      />
    </div>
  );
};

export default Vouchers;
