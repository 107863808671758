import React, { Key, useEffect, useRef, useState } from "react";
import {
  Avatar,
  Button,
  Input,
  Space,
  Table,
  TablePaginationConfig,
} from "antd";
import { SearchOutlined, UserOutlined } from "@ant-design/icons";

import CompanyService from "../../utils/CompanyService";
import { IFilterItems } from "../../utils/interfaces";
import { imageUrl } from "../../helpers/urlHelpers";
import { Link } from "react-router-dom";
import { FilterDropdownProps } from "antd/lib/table/interface";
import Highlighter from "react-highlight-words";

interface ICompanyItem {
  key: number;
  name: string;
  logo: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
}

const Companies: React.FC = () => {
  const [dataSource, setDataSource] = useState<ICompanyItem[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState<IFilterItems>({
    current: 1,
    pageSize: 10,
    email: "",
    companyName: "",
  });
  const [state, setState] = useState({ searchText: "", searchedColumn: "" });
  const emailSearchInput = useRef<Input>(null);
  const companyNameSearchInput = useRef<Input>(null);

  useEffect(() => {
    const getInterpreters = async () => {
      if (!filter.current || !filter.pageSize) return;
      setLoading(true);

      const { companies, count } = await CompanyService.getCompanies(filter);

      setTotalCount(count);
      const dataToSet: ICompanyItem[] = companies.map((company) => {
        const companyItem: ICompanyItem = {
          key: company.id,
          name: company.name,
          logo: company?.logo?.name,
          firstName: company.user.firstName,
          lastName: company.user.lastName,
          phone: company.user.phone,
          email: company.user.email,
        };
        return companyItem;
      });
      setDataSource(dataToSet);

      setLoading(false);
    };
    getInterpreters();
  }, [filter]);

  const handleTableChange = ({
    pagination,
  }: {
    pagination: TablePaginationConfig;
  }) => {
    const { current, pageSize } = pagination;

    setFilter({
      ...filter,
      current: current as number,
      pageSize: pageSize as number,
      [state.searchedColumn]: state.searchText,
    });
  };

  const handleSearch = (
    selectedKeys: Key[],
    confirm: () => void,
    dataIndex: string
  ) => {
    confirm();
    setState({
      searchText: selectedKeys[0] as string,
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters: (() => void) | undefined) => {
    if (!clearFilters) return;
    clearFilters();
    setState({ searchText: "", searchedColumn: "" });
  };

  const getRefToSet = (dataIndex: string) => {
    switch (dataIndex) {
      case "companyName":
        return companyNameSearchInput;
      case "email":
        return emailSearchInput;
    }
  };

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: FilterDropdownProps) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={getRefToSet(dataIndex)}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            setState({
              searchText: e.target.value,
              searchedColumn: dataIndex,
            });
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            onClick={() => {
              setState({ searchText: "", searchedColumn: "" });

              handleReset(clearFilters);
              setTimeout(() => getRefToSet(dataIndex)?.current?.focus(), 100);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setState({
                searchText: selectedKeys[0] as string,
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => getRefToSet(dataIndex)?.current?.focus(), 100);
      }
    },
    render: (text: string) =>
      state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Id",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Logo",
      dataIndex: "logo",
      key: "logo",
      render: (name: string) => (
        <Avatar size={42} icon={<UserOutlined />} src={imageUrl(name)} />
      ),
    },
    {
      title: "Company Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("companyName"),
    },
    {
      title: "Name",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Surname",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ...getColumnSearchProps("email"),
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Details",
      dataIndex: "key",
      key: "key",
      render: (id: string) => (
        <Link to={`/admin/companies/${id}`}>Details</Link>
      ),
    },
  ];

  return (
    <div className="App">
      <p>Companies</p>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={{
          ...filter,
          total: totalCount,
          pageSizeOptions: ["10", "25", "50"],
          showSizeChanger: true,
        }}
        loading={loading}
        onChange={(pagination) => handleTableChange({ pagination })}
      />
    </div>
  );
};

export default Companies;
