import React, { useRef } from "react";
import { Button, Modal } from "antd";
import { ErrorMessage, Formik, FormikProps } from "formik";
import { Form, Input } from "formik-antd";
import Text from "antd/lib/typography/Text";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  interpreterId: Yup.number().required("Interpreter is required."),
});

type PropTypes = {
  show: boolean;
  handleClose: () => void;
  confirm: (
    interpreterId: number,
    setSubmitting: (isSubmitting: boolean) => void,
    clearState: () => void,
    setError: (error: string) => void
  ) => void;
};

const InviteInterpreter: React.FC<PropTypes> = ({
  show,
  handleClose,
  confirm,
}) => {
  const formikRef = useRef<FormikProps<{ interpreterId?: number }>>(null);
  const handleConfirm = (
    setSubmitting: (isSubmitting: boolean) => void,
    resetForm: () => void,
    setError: (error: string) => void,
    interpreterId?: number
  ) => {
    if (!interpreterId) return;
    confirm(interpreterId, setSubmitting, resetForm, setError);
  };
  return (
    <Modal
      title="Invite interpreter to session:"
      visible={show}
      onCancel={() => {
        handleClose();
        formikRef.current?.resetForm();
      }}
      footer={null}
    >
      <div className="createVoucher">
        <Formik
          innerRef={formikRef}
          initialValues={{
            interpreterId: undefined,
          }}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={(values, { setSubmitting, resetForm, setFieldError }) => {
            handleConfirm(
              setSubmitting,
              resetForm,
              (msg: string) => setFieldError("interpreterId", msg),
              values.interpreterId
            );
          }}
        >
          {({ isSubmitting, submitForm }) => (
            <Form>
              <div className="dateGroup">
                <Text strong>Interpreter id:</Text>
                <Input name="interpreterId" type="number" min={1} />
                <ErrorMessage
                  name="interpreterId"
                  component="p"
                  className="error"
                />
              </div>
              <Button
                className="saveBtn"
                onClick={submitForm}
                disabled={isSubmitting}
                loading={isSubmitting}
                type="primary"
              >
                Invite
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default InviteInterpreter;
