import { useState } from "react";
import { Link, Navigate, Outlet, useLocation } from "react-router-dom";
import { Layout, Menu, message } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import {
  GiftOutlined,
  LogoutOutlined,
  MailOutlined,
  SearchOutlined,
  SolutionOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";

import { useAuth } from "../../hooks/authHook";

import "./index.scss";

const { Content, Sider } = Layout;

const AdminLayout: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false);
  const auth = useAuth();
  const location = useLocation();
  if (!auth.user) {
    return <Navigate to="/sign-in" state={{ from: location }} />;
  }

  const getSubMenuKey = () => {
    if (location.pathname.includes("interpreters")) return ["interpreters"];
    return [];
  };

  const logout = () => {
    auth.signout(message.info("Successfully logouted!"));
  };

  return (
    <Layout>
      <Sider
        style={{
          overflow: "auto",
          // height: "100vh",
          left: 0,
        }}
        theme="light"
        collapsible
        onCollapse={(col) => setCollapsed(col)}
        className={collapsed ? "collapsed" : "not-collapsed"}
      >
        <div className="logo">
          {collapsed ? (
            <img
              className="smallLogo"
              src="/img/dolmx_short_form.png"
              alt="logo"
            />
          ) : (
            <img src="/img/dolmX_Logo.svg" alt="logo" />
          )}
        </div>
        <Menu
          theme="light"
          mode="inline"
          defaultSelectedKeys={[location.pathname + location.search]}
          defaultOpenKeys={getSubMenuKey()}
        >
          <Menu.Item key="/admin" icon={<UserOutlined />}>
            <Link to="/admin">Dashboard</Link>
          </Menu.Item>
          <SubMenu
            key="interpreters"
            title="Interpreters"
            icon={<UserOutlined />}
          >
            <Menu.Item key="/admin/interpreters" icon={<UserOutlined />}>
              <Link to="/admin/interpreters">All</Link>
            </Menu.Item>
            <Menu.Item
              key="/admin/interpreters?status=active"
              icon={<UserOutlined />}
            >
              <Link to="/admin/interpreters?status=active">Active</Link>
            </Menu.Item>
            <Menu.Item
              key="/admin/interpreters?status=not-active"
              icon={<UserOutlined />}
            >
              <Link to="/admin/interpreters?status=not-active">Not Active</Link>
            </Menu.Item>
          </SubMenu>
          <Menu.Item key="/admin/companies" icon={<SolutionOutlined />}>
            <Link to="/admin/companies">Companies</Link>
          </Menu.Item>
          <Menu.Item key="/admin/videosessions" icon={<VideoCameraOutlined />}>
            <Link to="/admin/videosessions">Videosessions</Link>
          </Menu.Item>
          <Menu.Item key="/admin/vouchers" icon={<GiftOutlined />}>
            <Link to="/admin/vouchers">Vouchers</Link>
          </Menu.Item>
          <Menu.Item key="/admin/feedbacks" icon={<MailOutlined />}>
            <Link to="/admin/feedbacks">Feedbacks</Link>
          </Menu.Item>
          <Menu.Item key="/admin/find-interpreter" icon={<SearchOutlined />}>
            <Link to="/admin/find-interpreter">Find Interpreter</Link>
          </Menu.Item>
          <Menu.Item
            key="/logout"
            className={`logout-btn ${collapsed && "collapsed"}`}
            onClick={logout}
            icon={<LogoutOutlined />}
          >
            Logout
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        <Content
          style={{
            margin: "24px 16px 0",
            overflow: "initial",
            minHeight: "100vh",
          }}
        >
          <div
            className="site-layout-background"
            style={{ padding: 24, textAlign: "center" }}
          >
            <Outlet />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default AdminLayout;
